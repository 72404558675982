import _ from 'lodash';
import moment from 'moment';
import hash from 'react-router-history';
import swal from 'sweetalert2';

import * as a from '../actionsTypes/actionTypes';
import laris from '../api/laris';
import { newGuid, formatarNumeroGeral } from '../utils/Functions';
import {
  sendErroRequest,
  createInfoNotification,
  createSuccessNotification,
  createErrorNotification,
} from '../utils/Notification';
import { adicionarItemRelatorio } from './gerenciadorRelatorioActions';

const hashHistory = hash;

export const setFaturaSelecionada = response => ({
  type: a.FTR_SET_FATURA_SELECIONADA,
  payload: response,
});

export const setValorTotalFaturas = valor => ({
  type: a.FTR_SET_VALOR_TOTAL_FATURAS,
  payload: valor,
});

export const processandoBaixaFatura = bool => ({
  type: a.FTR_PROCESSANDO_BAIXA_FATURA,
  payload: bool,
});

// export const fazendoBaixaColetivaFaturas = bool => ({
//   type: a.FTR_BAIXA_COLETIVA_FATURAS,
//   payload: bool,
// });

export const getFaturasSuccess = response => ({
  type: a.FTR_GET_FATURAS,
  payload: response,
});

export const getFaturasSelecionadas = response => ({
  type: a.FATURAS_SELECIONADAS_CHECKED,
  payload: response,
});

export const setListaItensFatura = lista => ({
  type: a.FTR_SET_LISTA_ITENS_FATURA,
  payload: lista,
});

export const adicionandoItemFatura = bool => ({
  type: a.FTR_ADICIONANDO_ITEM_FATURA,
  payload: bool,
});

export const showModalItemFatura = bool => ({
  type: a.FTR_SHOW_MODAL_ITEM_FATURA,
  payload: bool,
});

// export const showModalBaixaFatura = bool => ({
//   type: a.FTR_SHOW_MODAL_BAIXA_FATURA,
//   payload: bool,
// });

export const setFaturasEmAberto = response => ({
  type: a.FTR_GET_FATURAS_EM_ABERTO,
  payload: response,
});
export const setFaturasEmAbertoMarcadas = response => ({
  type: a.FTR_GET_FATURAS_EM_ABERTO_MARCADAS,
  payload: response,
});

export const processandoFatura = bool => ({
  type: a.FTR_PROCESSANDO_FATURA,
  payload: bool,
});

export const gerandoFaturas = bool => ({
  type: a.FTR_GERANDO_FATURAS,
  payload: bool,
});

// //#endregion

export const initFormGerarFatura = () => async (dispatch) => {
  try {
    const { data } = await laris.get(`/faturas/buscar-data-vencimento-para-geracao-faturas`);

    return data;
  } catch (error) {
    sendErroRequest(error, 'Erro ao busca a data inicial de vencimento!');
  }
};

// export const limparFormularioItemFatura = () => dispatch =>
//   dispatch(
//     initialize('itemFaturaForm', {
//       id: Util.newGuid(),
//       tipoLancamento: 1,
//     }),
//   );

export const limparFaturasEmAbertoAcordo = () => (dispatch) => {
  try {
    dispatch(processandoFatura(true));
    dispatch(setFaturasEmAbertoMarcadas([]));
    dispatch(setFaturasEmAberto([]));

    dispatch(processandoFatura(false));
  } catch (error) {
    sendErroRequest(error, 'Erro ao limpar as faturas em aberto');
  }
};
export const marcarDesmarcar = (item, faturasEmAberto) => async (dispatch) => {
  try {
    dispatch(processandoFatura(true));

    // let valorDevido = 0.0;

    const faturasAtualizadas = await faturasEmAberto.map((taxa) => {
      const faturaNova = item.find(c => c.id === taxa.id);

      if (!faturaNova) return taxa;
      return { ...faturaNova, marcada: true };
    });

    // const faturasMarcadas = faturasAtualizadas
    //   .filter(fat => fat.checked)
    //   .map(fat => fat.valorDaFatura);

    // if (faturasMarcadas.length > 0) {
    //   valorDevido = faturasMarcadas.reduce((total, valorAtual) => total + valorAtual);
    // }
    // valorDevido = valorDevido - props.values.valorCredito;

    dispatch(setFaturasEmAbertoMarcadas(faturasAtualizadas));

    setTimeout(() => dispatch(processandoFatura(false)), 0.0001);
  } catch (error) {
    sendErroRequest(error, 'Erro ao selecionar Faturas');
  }
};

export const marcarDesmarcarFatura = item => async (dispatch) => {
  try {
    if (item.length === 0) {
      dispatch(getFaturasSelecionadas([]));
    } else {
      const faturasAtualizadas = await item.map(fat => ({ ...fat, checked: true }));

      dispatch(getFaturasSelecionadas(faturasAtualizadas));
    }
  } catch (error) {
    sendErroRequest(error, 'Erro ao marcar faturas!');
  }
};

export const marcarConsultaBaixaFaturas = (item, faturas) => async (dispatch) => {
  try {
    if (item.length === 0) {
      dispatch(getFaturasSelecionadas([]));
    } else {
      const faturasAtualizadas = await faturas.map((fat) => {
        const faturaNova = item.find(f => f.id === fat.id);
        if (!faturaNova) return fat;
        return { ...fat, checked: true };
      });
      dispatch(getFaturasSelecionadas(faturasAtualizadas));
    }
  } catch (error) {
    sendErroRequest(error, 'Erro ao marcar faturas!');
  }
};

const substituirFaturaNaLista = (lista, fatura) => {
  const novaLista = [...lista];

  const foundIndex = lista.findIndex(x => x.id === fatura.id);
  novaLista[foundIndex] = fatura;

  return novaLista;
};

const substituirFaturaNaListaEstorno = (lista, faturas) => {
  const faturasAtualizadas = lista.map((li) => {
    const faturaNova = faturas.find(fat => fat.id === li.id);
    if (!faturaNova) return li;

    return faturaNova;
  });

  return faturasAtualizadas;
};

// export const preparaBaixaColetivaFaturas = (values, faturas) => async dispatch => {
//   try {
//     const faturasSelecionadas = faturas.filter(fat => fat.checked);

//     if (faturasSelecionadas.length === 0)
//       return createInfoNotification('Selecione ao menos uma fatura para fazer a baixa coletiva!');

//     dispatch(fazendoBaixaColetivaFaturas(true));
//     dispatch(showModalBaixaFatura(true));
//   } catch (error) {
//     sendErroRequest(error, 'Erro ao preparar Faturas !');
//   }
// };

export const baixarFaturasEmModoColetivo = (values, faturas) => async (dispatch) => {
  try {
    if (!values)
      return createInfoNotification(
        'Informe os dados obrigatórios para a baixa coletiva de faturas para continuar!',
      );

    if (!values.dataPagamento)
      return createInfoNotification(
        'Informe a data de pagamento para fazer a baixa coletiva de faturas!',
      );

    const faturasSeleciondasBaixaColetiva = values.faturasSelecionadas
      .filter(fat => fat.checked && fat.statusDaFatura !== 2)
      .map(item => item.id);

    const dadosBaixaFatura = {
      dataPagamento: values.dataPagamento,
      acrescimo: values.acrescimo || 0,
      desconto: values.desconto || 0,
      faturasSeleciondasBaixaColetiva,
    };

    dispatch(processandoBaixaFatura(true));
    dispatch(processandoFatura(true));

    const { data } = await laris.post(
      `/consulta-baixa-faturas/baixarFaturasEmModoColetivo`,
      dadosBaixaFatura,
    );

    if (data.contemErros) data.erros.map(err => createErrorNotification(err.mensagem));
    else createSuccessNotification(data.mensagem);

    const novaLista = faturas.map((fat) => {
      const foundIndex = data.faturasBaixadas.findIndex(x => x.id === fat.id);

      if (foundIndex > -1) {
        return { ...data.faturasBaixadas[foundIndex], nossoNumero: fat.nossoNumero };
      }
      return fat;
    });

    dispatch(getFaturasSuccess(novaLista));
    dispatch(processandoBaixaFatura(false));

    dispatch(processandoFatura(false));
  } catch (error) {
    dispatch(processandoBaixaFatura(false));
    dispatch(processandoFatura(false));
    sendErroRequest(error, 'Ocorreu um erro ao tentar baixas as faturas selecionadas!');
  }
};

export const excluirFatura = (dataItem, faturas) => async (dispatch) => {
  try {
    const res = await swal.fire({
      title: 'Confirmar deleção!',
      text: 'Você tem certeza que deseja deletar esse item?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Realmente deseja deletar?',
      cancelButtonText: 'Cancelar',
    });

    if (res.value) {
      dispatch(processandoFatura(true));
      await laris.delete(`/consulta-baixa-faturas/excluirFatura/${dataItem.id}`);

      const novaLista = faturas.filter(fat => fat.id !== dataItem.id);

      dispatch(getFaturasSuccess(novaLista));
      dispatch(processandoFatura(false));
      createSuccessNotification('Fatura deletada com sucesso!');
    }
  } catch (error) {
    dispatch(processandoFatura(false));
    sendErroRequest(error, 'Ocorreu um erro ao excluir a fatura selecionada!');
  }
};

export const auditarFaturasBaixadasSelecionadas = faturas => async (dispatch) => {
  try {
    const faturasSelecionadas = faturas.map(f => f.id);

    if (faturasSelecionadas.length === 0)
      return createInfoNotification('É necessário selecionar Faturas !');

    dispatch(processandoFatura(true));

    const { data } = await laris.post(
      `auditoria-faturas-baixadas/auditarFaturas`,
      faturasSelecionadas,
    );
    dispatch(getFaturasSuccess(data));
    dispatch(processandoFatura(false));

    createSuccessNotification('Faturas auditadas com sucesso!');
  } catch (error) {
    dispatch(processandoFatura(false));
    sendErroRequest(error, 'Erro ao auditar as faturas');
  }
};

export const auditarFaturasEmAbertoSelecionadas = faturas => async (dispatch) => {
  try {
    const faturasSelecionadas = faturas.map(f => f.id);
    if (faturasSelecionadas.length === 0)
      return sendErroRequest(null, 'Ocorreu um erro ao carregar as faturas!');

    dispatch(processandoFatura(true));

    const { data } = await laris.post(
      'auditoria-faturas-em-aberto/auditarFaturas',
      faturasSelecionadas,
    );

    dispatch(getFaturasSuccess(data));
    dispatch(processandoFatura(false));

    createSuccessNotification('Faturas Auditadas com sucesso!');
  } catch (error) {
    dispatch(processandoFatura(false));
    sendErroRequest(error, 'Erro ao auditar as faturas');
  }
};

// export const marcarDesmarcarTodasFaturas = (value, status, marcada) => (dispatch, getState) => {
//   const {
//     fatura: { faturas },
//   } = getState();

//   dispatch(processandoFatura(true));

//   var listaFaturas = faturas.map(fat => ({
//     ...fat,
//     checked: status.includes(fat.statusFatura) && fat.marcada == marcada ? value : fat.checked,
//   }));

//   dispatch(getFaturasSuccess(listaFaturas));

//   setTimeout(() => dispatch(processandoFatura(false)), 10);
// };

// export const abrirFecharModalBaixaFatura = bool => dispatch => dispatch(showModalBaixaFatura(bool));

export const prepararFaturaParaBaixa = dataItem => async (dispatch) => {
  try {
    dispatch(processandoBaixaFatura(true));

    const { data } = await laris.post(
      `/consulta-baixa-faturas/prepararBaixaManual/${dataItem.id}`,
      {},
    );

    const faturaAtualizada = {
      ...dataItem,
      dataPagamento: data.dadosBaixaManual.dataPagamento,
      juro: data.dadosBaixaManual.juro,
      multa: data.dadosBaixaManual.multa,
      valorDoAcrescimo: data.dadosBaixaManual.acrescimo,
      valorDoDesconto: data.dadosBaixaManual.desconto,
      valorDiferenca: data.dadosBaixaManual.valorDiferenca,
      honorariosAdvocaticios: data.dadosBaixaManual.honorariosAdvocaticios,
    };
    dispatch(setFaturaSelecionada(faturaAtualizada));

    dispatch(processandoBaixaFatura(false));
  } catch (error) {
    dispatch(processandoBaixaFatura(false));
    sendErroRequest(
      error,
      'Ocorreu um erro ao tentar buscar os dados de preparação para a baixa manual. Verifique os dados e tente novamente!',
    );
  }
};

// export const recalcularBaixaFatura = (field, value) => (dispatch, getState) => {
//   dispatch(change('modalBaixaFaturaForm', field, value));

//   const { form, fatura } = getState();

//   var baixaFatura = form.modalBaixaFaturaForm.values;

//   var valorPago = 0.0;
//   var valorDiferenca = 0.0;

//   valorPago = valorPago + baixaFatura.acrescimo;
//   valorPago = valorPago + baixaFatura.multa;
//   valorPago = valorPago + baixaFatura.juro;
//   valorPago = valorPago + baixaFatura.honorariosAdvocaticios;
//   valorPago = valorPago + baixaFatura.valorFundoReserva;
//   valorPago = valorPago + baixaFatura.valorTaxa;
//   valorPago = valorPago - baixaFatura.desconto;

//   valorDiferenca = valorPago - fatura.faturaSelecionada.valorDaFatura;

//   dispatch([
//     change('modalBaixaFaturaForm', 'valorPago', valorPago),
//     change('modalBaixaFaturaForm', 'valorDiferenca', valorDiferenca),
//   ]);
// };

export const onChangeDataPagamentoFatura = (dataValue, faturaSelecionada) => async (dispatch) => {
  try {
    if (dataValue === null)
      return createInfoNotification('Data inválida. Verifique os dados e tente novamente!');
    const dataPagamento = moment(dataValue);
    dispatch(processandoBaixaFatura(true));

    const { data } = await laris.post(
      `/consulta-baixa-faturas/prepararBaixaManual/${faturaSelecionada.id}`,
      dataPagamento,
    );

    const faturaAtualizada = {
      ...faturaSelecionada,
      dataPagamento: data.dadosBaixaManual.dataPagamento,
      juro: data.dadosBaixaManual.juro,
      multa: data.dadosBaixaManual.multa,
      valorDoAcrescimo: data.dadosBaixaManual.acrescimo,
      valorDoDesconto: data.dadosBaixaManual.desconto,
      valorDiferenca: data.dadosBaixaManual.valorDiferenca,
      honorariosAdvocaticios: data.dadosBaixaManual.honorariosAdvocaticios,
    };
    dispatch(setFaturaSelecionada(faturaAtualizada));

    dispatch(processandoBaixaFatura(false));
  } catch (error) {
    dispatch(processandoBaixaFatura(false));
  }
};

export const getFaturasFiltradas = values => async (dispatch) => {
  try {
    const filtro = {
      statusDaFatura: values.statusDaFatura || 0,
      dataInicioVencimento: values.periodoVencimentoInicial
        ? values.periodoVencimentoInicial
        : undefined,
      dataFimVencimento: values.periodoVencimentoFinal ? values.periodoVencimentoFinal : undefined,
      inicioCompetencia: values.periodoCompetenciaInicial
        ? values.periodoCompetenciaInicial
        : undefined,
      fimCompetencia: values.periodoCompetenciaFinal ? values.periodoCompetenciaFinal : undefined,
      unidade_Id: values.unidadeId || undefined,
    };

    dispatch(processandoFatura(true));

    const { data } = await laris.post(`/consulta-baixa-faturas/getFaturasFiltradas`, filtro);

    dispatch(getFaturasSuccess(data.faturas));
    dispatch(processandoFatura(false));
  } catch (error) {
    dispatch(processandoFatura(false));
    sendErroRequest(error, 'Ocorreu um erro ao carregar as faturas!');
  }
};

// export const getFaturasEmAberto = idUnidade => async dispatch => {
//   try {
//     dispatch(processandoFatura(true));

//     const { data } = await laris.get(`/faturas/getFaturasEmAberto/${idUnidade}`);

//     dispatch([
//       setFaturasEmAberto([...data.filter(fat => !fat.acordada)]),
//       processandoFatura(false),
//     ]);
//   } catch (err) {
//     dispatch(processandoFatura(false));
//     tratamentoErroRequest(
//       err,
//       'Fatura',
//       'Ocorreu um erro inesperado au tentar buscar as faturas em aberto!',
//     );
//   }
// };

export const getFaturasEmAbertoAcordo = (unidadeId, props) => async (dispatch) => {
  try {
    // dispatch(change('acordoForm', 'dataPrimeiroVencimento', dataPrimeiroVencimento));
    // dispatch(change('acordoForm', 'unidade.id', unidadeId));

    if (!unidadeId) return createInfoNotification('Unidade Inválida');

    if (!props.dataPrimeiroVencimento) {
      return createInfoNotification('E necessário informar  uma data para Primeiro Vencimento');
    }
    dispatch(processandoFatura(true));

    const dataPrimeiroPagamento = moment(props.dataPrimeiroVencimento).format('YYYYMMDD');

    const { data } = await laris.get(
      `/faturas/getFaturasEmAbertoAcordo?idUnidade=${unidadeId}&dataPagamento=${dataPrimeiroPagamento}&aplicaJurosMulta=${props.aplicaJurosParcelamento}`,
    );

    dispatch(setFaturasEmAberto([...data.filter(fat => !fat.acordada)]));
    dispatch(processandoFatura(false));
  } catch (err) {
    dispatch(processandoFatura(false));
    sendErroRequest(err, 'Ocorreu um erro inesperado ao  buscar as faturas em aberto!');
  }
};

export const simularGeracaoFaturas = (taxasMarcadas, formFatura) => async (dispatch) => {
  try {
    if (taxasMarcadas.length < 1)
      return createInfoNotification('Selecione ao menos uma taxa para fazer a simulação!');

    const baseGeracaoFaturas = {
      mes: parseInt(moment(formFatura.periodo).format('M')),
      ano: parseInt(moment(formFatura.periodo).year()),
      vencimento: moment(formFatura.dataVencimento),
      taxas: taxasMarcadas,
      unidades: [],
    };

    dispatch(processandoFatura(true));
    const { data } = await laris.post(
      `/faturas/simularGeracaoFaturasPelasTaxas`,
      baseGeracaoFaturas,
    );

    let valorTotalFaturas = 0;

    const faturas = data.map((fat) => {
      valorTotalFaturas += fat.valorDaFatura;
      return {
        ...fat,
        dataDoVencimento: moment(fat.dataDoVencimento).format('DD/MM/YYYY'),
      };
    });

    dispatch(getFaturasSuccess(faturas));
    dispatch(setValorTotalFaturas(valorTotalFaturas.toFixed(2)));
    dispatch(processandoFatura(false));

    if (valorTotalFaturas === 0)
      return createInfoNotification('Não foi encontrada nenhuma previsão com faturas à gerar!');
  } catch (error) {
    dispatch(processandoFatura(false));
    sendErroRequest(error, 'Ocorreu um erro ao buscar as Faturas!');
  }
};

// export const limparFomularioFaturaAvulsa = () => dispatch => {
//   dispatch(adicionandoItemFatura(true));
//   dispatch([
//     setEstadoInicial(),
//     initialize('faturaAvulsaForm', {
//       id: Util.newGuid(),
//       valorDaFatura: 0,
//       valorDoAcrescimo: 0,
//       valorDoDesconto: 0,
//       valorDoFundoDeReserva: 0,
//       itensDaFatura: [],
//     }),
//   ]);
//   dispatch(adicionandoItemFatura(false));
// };

// export const onChangeRecalcularValorTotal = (value, field) => (dispatch) => {
//   const { form, fatura } = getState();

//   let valorTotal = 0;

//   _.forEach(fatura.itensFatura, item => {
//     valorTotal = valorTotal + item.valor;
//   });

//   let valorDoFundoDeReserva = 0;
//   let valorDoDesconto = 0;
//   let valorDoAcrescimo = 0;

//   switch (field) {
//     case 'valorDoFundoDeReserva':
//       valorDoFundoDeReserva = value;
//       valorDoDesconto = form.faturaAvulsaForm.values.valorDoDesconto;
//       valorDoAcrescimo = form.faturaAvulsaForm.values.valorDoAcrescimo;
//       break;
//     case 'valorDoDesconto':
//       valorDoFundoDeReserva = form.faturaAvulsaForm.values.valorDoFundoDeReserva;
//       valorDoDesconto = value;
//       valorDoAcrescimo = form.faturaAvulsaForm.values.valorDoAcrescimo;
//       break;
//     case 'valorDoAcrescimo':
//       valorDoFundoDeReserva = form.faturaAvulsaForm.values.valorDoFundoDeReserva;
//       valorDoDesconto = form.faturaAvulsaForm.values.valorDoDesconto;
//       valorDoAcrescimo = value;
//       break;
//     default:
//       break;
//   }

//   valorTotal = valorTotal + valorDoFundoDeReserva - valorDoDesconto + valorDoAcrescimo;

//   dispatch(change('faturaAvulsaForm', 'valorDaFatura', valorTotal));
// };

const recalcularTotalItensFatura = listaItensFatura => (dispatch) => {
  try {
    let valorTotal = 0;
    listaItensFatura.map((itemFatura) => {
      valorTotal += itemFatura.tipoLancamento === 2 ? itemFatura.valor * -1 : itemFatura.valor;
    });
    dispatch(setValorTotalFaturas(valorTotal));
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um ero ao adicionar um item na taxa!');
  }

  // if (faturaAvulsaForm && faturaAvulsaForm.values) {
  //   var valorDoAcrescimo = faturaAvulsaForm.values.valorDoAcrescimo || 0;
  //   var valorDoDesconto = faturaAvulsaForm.values.valorDoDesconto || 0;
  //   var valorDoFundoDeReserva = faturaAvulsaForm.values.valorDoFundoDeReserva || 0;

  //   valorTotal += valorDoAcrescimo + valorDoFundoDeReserva - valorDoDesconto;
  // }

  // dispatch(change('faturaAvulsaForm', 'valorDaFatura', valorTotal),
};

export const limparFomulario = () => (dispatch) => {
  dispatch(setListaItensFatura([]));
  dispatch(setValorTotalFaturas(0));
};

export const addItemFaturaLista = (value, lista) => (dispatch) => {
  try {
    const taxa = { id: value.taxaId };
    const conta = { id: value.contaId };
    const historico = { id: value.historicoId };
    dispatch(adicionandoItemFatura(true));
    const itemFatura = {
      taxa,
      conta,
      historico,
      ...value,

      valor: formatarNumeroGeral(value.valor),
      id: newGuid(),
    };

    const novaLista = [...lista, itemFatura];
    dispatch(recalcularTotalItensFatura(novaLista));
    dispatch(setListaItensFatura(novaLista));
    dispatch(showModalItemFatura(false));

    dispatch(adicionandoItemFatura(false));
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um ero ao adicionar um item na taxa!');
  }
};

// export const abrirFecharModal = bool => dispatch => dispatch(showModalItemFatura(bool));

// export const carregarItensFatura = dataItem => dispatch => {
//   dispatch(adicionandoItemFatura(true));
//   dispatch([
//     setListaItensFatura(dataItem.itensDaFatura),
//     setFaturaSelecionada(dataItem),
//     showModalItemFatura(true),
//   ]);
//   setTimeout(() => dispatch(adicionandoItemFatura(false)), 200);
// };

export const exclusaoColetivaDeFaturas = faturasSelecionadas => async (dispatch) => {
  try {
    const listanovaFaturas = faturasSelecionadas.filter(
      fat => fat.statusFatura !== 2 && fat.checked,
    );
    if (listanovaFaturas.length === 0)
      return createInfoNotification(
        'Selecione ao menos uma fatura não baixada para fazer a exclusão',
      );
    const res = await swal.fire({
      title: 'Confirmar exclusão coletiva de faturas!',
      text: 'Você tem certeza que deseja excluir as faturas selecionadas ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Realmente deseja deletar?',
      cancelButtonText: 'Cancelar',
    });

    if (res.value) {
      const ids = listanovaFaturas.filter(fat => fat.checked).map(fat => fat.id);

      if (ids.length === 0)
        return createInfoNotification('Selecione ao menos uma fatura para fazer a exclusão');

      dispatch(processandoFatura(true));
      await laris.post(`/faturas/exclusao-coletiva-faturas`, ids);

      const listaNovaFaturas = faturasSelecionadas.filter(fat => !fat.checked);

      dispatch(getFaturasSuccess(listaNovaFaturas));
      dispatch(processandoFatura(false));
      createSuccessNotification('Faturas excluídas com sucesso!');
    }
  } catch (error) {
    dispatch(processandoFatura(false));
    sendErroRequest(error, 'Ocorreu um erro inesperado ao tentar fazer a exclusão coletiva ');
  }
};

// export const onChangeTaxaModalItenFatura = id => (dispatch, getState) => {
//   const {
//     taxa: { taxas },
//   } = getState();

//   const [taxaSelecionada] = taxas.filter(tax => tax.id === id);

//   dispatch(change('itemFaturaForm', 'taxa', taxaSelecionada));
// };

export const removeItemFaturaLista = (item, itensFatura) => (dispatch) => {
  try {
    dispatch(adicionandoItemFatura(true));

    const novaLista = [...itensFatura.filter(itemFatura => itemFatura.id !== item)];

    dispatch(recalcularTotalItensFatura(novaLista));
    dispatch(setListaItensFatura(novaLista));

    setTimeout(() => dispatch(adicionandoItemFatura(false)), 1);
  } catch (error) {
    dispatch(adicionandoItemFatura(false));
    sendErroRequest(error, 'Erro ao remover um item da Fatura avulsa!');
  }
};

export const postFaturaAvulsa = values => async (dispatch) => {
  try {
    if (values.dataDoVencimento < values.dataReferencia)
      return createInfoNotification(
        'A data de referência não pode ser maior que a data de vencimento!',
      );

    if (values.itensFatura.length === 0)
      return createInfoNotification('Não é possível lançar uma fatura sem nenhum item!');

    const fatura = {
      ...(values.id && { id: values.id }),
      ...(values.descricao && { descricao: values.descricao }),
      ...(values.unidadeId && { unidade: { id: values.unidadeId } }),
      ...(values.dataReferencia && { dataReferencia: values.dataReferencia }),
      ...(values.dataDoVencimento && { dataDoVencimento: values.dataDoVencimento }),
      ...(values.valorDaFatura && { valorDaFatura: values.valorDaFatura }),
      ...(values.itensFatura && { itensDaFatura: values.itensFatura }),
      ...(values.valorDoFundoDeReserva && {
        valorDoFundoDeReserva: formatarNumeroGeral(values.valorDoFundoDeReserva),
      }),
      ...(values.valorDoDesconto && {
        valorDoDesconto: formatarNumeroGeral(values.valorDoDesconto),
      }),
      ...(values.valorDoAcrescimo && {
        valorDoAcrescimo: formatarNumeroGeral(values.valorDoAcrescimo),
      }),
    };

    const { data } = await laris.post(`/taxas-avulsa/gerarFaturaAvulsa`, fatura);

    createSuccessNotification('Fatura Avulsa cadastrada com sucesso!');

    // dispatch(limparFomularioFaturaAvulsa());
    // dispatch(setListaItensFatura([]));

    setTimeout(() => dispatch(processandoFatura(false)), 100);
  } catch (error) {
    dispatch(processandoFatura(false));
    sendErroRequest(
      error,
      'Ocorreu um erro ao gravar a fatura avulsa. Verifique os dados e tente novamente!',
    );
  }
};

export const limparListaFaturas = () => (dispatch, getState) => {
  dispatch(setValorTotalFaturas(0));

  dispatch(processandoFatura(true));
  dispatch(getFaturasSuccess([]));
  setTimeout(() => dispatch(processandoFatura(false)), 300);
};

export const auditarFatura = (dataItem, faturas) => async (dispatch) => {
  try {
    dispatch(processandoFatura(true));

    const res = await laris.get(
      `/auditoria-faturas-${dataItem.statusFatura === 2 ? 'baixadas' : 'em-aberto'}/auditarFatura/${dataItem.id
      }`,
    );

    const novaLista = substituirFaturaNaLista(faturas, res.data);

    dispatch(getFaturasSuccess(novaLista));
    dispatch(processandoFatura(false));
    createSuccessNotification('Fatura auditada com sucesso!');
  } catch (err) {
    dispatch(processandoFatura(false));
    sendErroRequest(
      err,
      'Ocorreu um erro ao tentar auditar a fatura. Verifique os dados e tente novamente!',
    );
  }
};

export const buscarFaturasEmAberto = values => async (dispatch) => {
  try {
    const filtro = {
      statusDaFatura: 2,
      ...(values.vencimentoInicial && { dataInicioVencimento: values.vencimentoInicial }),
      ...(values.vencimentoFinal && { dataFimVencimento: values.vencimentoFinal }),
      ...(values.competenciaInicial && { inicioCompetencia: values.competenciaInicial }),
      ...(values.competenciaFinal && { fimCompetencia: values.competenciaFinal }),
      ...(values.unidade && { unidade_Id: values.unidade }),
      ...(values.tipoFatura && { tipoFatura: values.tipoFatura }),
    };

    dispatch(processandoFatura(true));

    const { data } = await laris.post(`/auditoria-faturas-em-aberto/buscarFaturasEmAberto`, filtro);

    dispatch(getFaturasSuccess(data));

    dispatch(processandoFatura(false));
  } catch (error) {
    dispatch(processandoFatura(false));
    sendErroRequest(
      error,
      'Ocorreu um erro ao buscar as faturas. Verifique os dados e tente novamente!',
    );
  }
};

//   try {
//     const { form } = getState();

//     var buscaForm = form.buscaForm.values;

//     if (!buscaForm || !buscaForm.periodoCompetencia) {
//       toastr.error('Fatura', 'Informe o periodo de competência para fazer a busca!');
//       return;
//     }

//     var filtro = {
//       statusDaFatura: 1,
//       dataInicioVencimento: buscaForm.periodoVencimento
//         ? moment(buscaForm.periodoVencimento[0]).startOf('day')
//         : undefined,
//       dataFimVencimento: buscaForm.periodoVencimento
//         ? moment(buscaForm.periodoVencimento[1]).startOf('day')
//         : undefined,
//       inicioCompetencia: buscaForm.periodoCompetencia
//         ? moment(buscaForm.periodoCompetencia[0]).startOf('day')
//         : undefined,
//       fimCompetencia: buscaForm.periodoCompetencia
//         ? moment(buscaForm.periodoCompetencia[1]).startOf('day')
//         : undefined,
//       unidade_Id: buscaForm.unidade_Id || undefined,
//     };
//     dispatch(processandoFatura(true));
//     var res = await laris.post(`/auditoria-faturas-em-aberto/buscarFaturasEmAberto`, filtro);

//     dispatch([getFaturasSuccess(res.data), processandoFatura(false)]);
//   } catch (err) {
//     dispatch(processandoFatura(false));
//     tratamentoErroRequest(
//       err,
//       'Fatura',
//       'Ocorreu um erro ao buscar as faturas. Verifique os dados e tente novamente!',
//     );
//   }
// };

export const buscarFaturasBaixadasParaAuditoria = values => async (dispatch) => {
  try {
    const filtro = {
      statusDaFatura: 2,
      ...(values.vencimentoInicial && { dataInicioVencimento: values.vencimentoInicial }),
      ...(values.vencimentoFinal && { dataFimVencimento: values.vencimentoFinal }),
      ...(values.competenciaInicial && { inicioCompetencia: values.competenciaInicial }),
      ...(values.competenciaFinal && { fimCompetencia: values.competenciaFinal }),
      ...(values.unidade && { unidade_Id: values.unidade }),
      ...(values.tipoFatura && { tipoFatura: values.tipoFatura }),
    };

    dispatch(processandoFatura(true));
    const { data } = await laris.post(`/auditoria-faturas-baixadas/buscarFaturasBaixadas`, filtro);

    dispatch(getFaturasSuccess(data));
    dispatch(processandoFatura(false));
  } catch (err) {
    dispatch(processandoFatura(false));
    sendErroRequest(
      err,
      'Ocorreu um erro ao buscar as faturas. Verifique os dados e tente novamente!',
    );
  }
};

export const buscarFaturaParaGeracaoRemessa = values => async (dispatch) => {
  try {
    if (!values || !values.periodo)
      return sendErroRequest('Buscar faturas', 'Informe o período para buscar as faturas!');

    const periodo = moment(values.periodo).format('YYYYMM');

    dispatch(processandoFatura(true));
    const { data } = await laris.get(`/faturas/buscarFaturaParaGeracaoRemessa/${periodo}`);

    dispatch(getFaturasSuccess(data));
    dispatch(processandoFatura(false));
  } catch (err) {
    dispatch(processandoFatura(false));
    sendErroRequest(err, 'Ocorreu um erro ao buscar as faturas para geração de remessa!');
  }
};

export const gerarFaturas = (taxasSelecionadas, formFatura, faturas) => async (dispatch) => {
  try {
    dispatch(processandoFatura(true));
    dispatch(gerandoFaturas(true));

    const unidadesSelecionadas = faturas
      .filter(fat => fat.checked)
      .map(item => item.unidade.id);

    const baseGeracaoFaturas = {
      mes: parseInt(moment(formFatura.periodo).format('M')),
      ano: parseInt(moment(formFatura.periodo).year()),
      vencimento: moment(formFatura.dataVencimento),
      taxas: taxasSelecionadas,
      unidades: unidadesSelecionadas,
    };

    dispatch(processandoFatura(true));
    const { data } = await laris.post(`/faturas/gerarFaturas`, baseGeracaoFaturas);

    dispatch(getFaturasSuccess([]));
    dispatch(gerandoFaturas(false));
    dispatch(processandoFatura(false));

    if (data.faturasGeradas.length > 0) createSuccessNotification('Fatura gerada com sucesso!');
  } catch (error) {
    dispatch(processandoFatura(false));
    dispatch(gerandoFaturas(false));
    sendErroRequest(
      error,
      'Ocorreu um erro ao gerar as faturas. Verifique os dados e tente novamente!',
    );
  }
};

export const emitirFaturas = values => async (dispatch) => {
  try {
    createSuccessNotification(
      'A solicitação de emissao de Faturas foi realizada com sucesso, verifique os resultados no sininho que está no canto superior esquerdo da tela!',
    );
    const faturas = {
      emitirApenasFaturasEmAberto: values.emitirFaturasAberto,
      periodoFinal: moment(values.dataFinal).format('YYYY-MM-DD'),
      periodoInicial: moment(values.dataInicial).format('YYYY-MM-DD'),
      ...(values.condominoId && { condomino_Id: values.condominoId }),
      ...(values.proprietarioId && { proprietario_Id: values.proprietarioId }),
      ...(values.unidadeId && { unidade_Id: values.unidadeId }),
      ...(values.unidadeCentralId && { unidadeCentral_Id: values.unidadeCentralId }),
      nossoNumero: values.nossoNumero,
      ...(values.vencimentoDataInicial && {
        vencimentoInicial: moment(values.vencimentoDataInicial).format('YYYY-MM-DD'),
      }),
      ...(values.vencimentoDataFinal && {
        vencimentoFinal: moment(values.vencimentoDataFinal).format('YYYY-MM-DD'),
      }),
      ...(values.layout && { layout: values.layout }),
    };
    const { data } = await laris.post('/relatorios/recibo-faturas', faturas);

    if (data.length === 0) {
      createInfoNotification('Não existe nenhum fatura à ser emitida');
    }

    data.forEach((item) => {
      dispatch(adicionarItemRelatorio(item));
    });
  } catch (err) {
    sendErroRequest(
      err,
      'Emissão de Fatura',
      'Ocorreu um erro inesparado na emissão da Fatura. Verifique os dados e tente novamente!',
    );
  }
};

export const estornarFatura = (item, faturas) => async (dispatch) => {
  try {
    const res = await swal.fire({
      title: 'Confirmar estorno!',
      text: 'Você tem certeza que deseja estornar essa Fatura?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#f8ac59',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Realmente deseja estornar?',
      cancelButtonText: 'Cancelar',
    });

    if (res.value) {
      dispatch(processandoFatura(true));
      const { data } = await laris.post(`/consulta-baixa-faturas/estornarFatura/${item.id}`, {});

      const novaListaFaturas = substituirFaturaNaLista(faturas, data.fatura);

      dispatch(getFaturasSuccess(novaListaFaturas));
      dispatch(processandoFatura(false));

      createSuccessNotification('Fatura Estornada com sucesso!');
    }
  } catch (error) {
    dispatch(processandoFatura(false));
    sendErroRequest(error, 'Ocorreu um erro ao carregar as faturas!');
  }
};

export const baixarFaturaManualmente = (values, faturas) => async (dispatch) => {
  try {
    if (values.valorPago == null) return createInfoNotification('É necessário um valor Pago!');
    const dadosBaixaForm = {
      id: values.id,
      dataPagamento: values.dataPagamento ? values.dataPagamento : null,
      acrescimo: values.acrescimo !== '' ? formatarNumeroGeral(values.acrescimo) : 0,
      desconto: values.desconto !== '' ? formatarNumeroGeral(values.desconto) : 0,
      honorariosAdvocaticios:
        values.honorariosAdvocaticios !== ''
          ? formatarNumeroGeral(values.honorariosAdvocaticios)
          : 0,
      multa: values.multa !== null ? formatarNumeroGeral(values.multa) : 0,
      juro: values.juro !== null ? formatarNumeroGeral(values.juro) : 0,
      valorPago: formatarNumeroGeral(values.valorPago),
    };

    dispatch(processandoBaixaFatura(true));
    dispatch(processandoFatura(true));
    const { data } = await laris.post(
      `/consulta-baixa-faturas/baixarFaturaManualmente/${values.id}`,
      dadosBaixaForm,
    );

    const novaListaFaturas = faturas.map((fat) => {
      if (fat.id === data.fatura.id) return data.fatura;
      return fat;
    });

    dispatch(getFaturasSuccess(novaListaFaturas));
    dispatch(setFaturaSelecionada({}));
    // initialize('modalBaixaFaturaForm', {}),
    // showModalBaixaFatura(false),
    dispatch(processandoFatura(false));
    dispatch(processandoBaixaFatura(false));

    createSuccessNotification('Fatura baixada com sucesso!');
  } catch (error) {
    dispatch(processandoBaixaFatura(false));
    dispatch(processandoFatura(false));
    sendErroRequest(error, ' Ocorreu um erro ao carregar as faturas!');
  }
};

export const EditarNossoNumeroFatura = (value, id, faturas) => async (dispatch) => {
  try {
    dispatch(processandoFatura(true));
    const form = {
      nossoNumero: value.nossoNumeroEdit,
      idFatura: id,
    };

    const { data } = await laris.put('/faturas/editarNossoNumeroFatura/', form);

    const faturasAtualizadas = faturas.map((fatura) => {
      const faturaNova = data.id === fatura.id;

      if (!faturaNova) return fatura;

      return data;
    });

    dispatch(getFaturasSuccess(faturasAtualizadas));
    dispatch(processandoFatura(false));
    createSuccessNotification('Nosso Número Alterado com sucesso!');
  } catch (error) {
    dispatch(processandoFatura(false));
    sendErroRequest(error, 'Erro ao editar Nosso Número!');
  }
};

export const estornoColetivoFatura = (faturasSelecionadas, faturas) => async (dispatch) => {
  try {
    const novaListaFaturas = faturasSelecionadas.filter(
      fat => fat.statusFatura === 2 && fat.checked,
    );
    if (novaListaFaturas.length <= 0)
      return createInfoNotification('É necessário selecionar Faturas Baixadas para o estorno!');

    dispatch(processandoFatura(true));
    const { data } = await laris.post(`/consulta-baixa-faturas/estornoColetivo`, novaListaFaturas);
    const listafaturasAtualizadas = substituirFaturaNaListaEstorno(faturas, data.faturas);

    dispatch(getFaturasSuccess(listafaturasAtualizadas));
    dispatch(processandoFatura(false));
    createSuccessNotification('Faturas estornadas com sucesso!');
  } catch (error) {
    dispatch(processandoFatura(false));
    sendErroRequest(error, 'Ocorreu um erro ao tentar estornar as faturas selecionadas!');
  }
};
