import moment from 'moment';
import hash from 'react-router-history';
import swal from 'sweetalert2';

import * as a from '../actionsTypes/actionTypes';
import laris from '../api/laris';
import {
  newGuid,
  formatarNumeroGeral,
  formatarValor,
  GUID_EMPTY,
} from '../utils/Functions';
import {
  createSuccessNotification,
  sendErroRequest,
  createInfoNotification,
} from '../utils/Notification';
import { parseIntDecimal } from '../utils/parseInt.utils';

const { hashHistory } = hash;

export const getPrevisoesSuccess = response => ({
  type: a.PRV_GET_PREVISOES,
  payload: response,
});

export const getPrevisoesSelecionadas = response => ({
  type: a.PRV_GET_PREVISOES_CHECKED,
  payload: response,
});

export const getPrevisoesDaImportacao = response => ({
  type: a.PRV_GET_PREVISOES_IMPORTACAO,
  payload: response,
});

export const processandoPrevisao = bool => ({
  type: a.PRV_PROCESSANDO_PREVISAO,
  payload: bool,
});

export const showModalMemoriaCalculo = (bool, conteudo) => dispatch =>
  dispatch({
    type: a.PRV_SHOW_MODAL_MEMORIA_CALCULO_ITEM_PREVISAO,
    payload: {
      show: bool,
      conteudo,
    },
  });

export const showModalGerarPrevisaoParcelamento = bool => dispatch =>
  dispatch({
    type: a.PRV_SHOW_MODAL_GERAR_PREVISAO_PARCELAMENTO,
    payload: bool,
  });

export const limparGridPrevisao = () => (dispatch) => {
  dispatch(processandoPrevisao(true));
  dispatch(setItensPrevisao([]));
  dispatch(getPrevisoesSuccess([]));

  dispatch(processandoPrevisao(false));
};

// export const limparModalGerarPrevisaoParcelamento = () => dispatch =>
//   dispatch(initialize('modalGerarPrevisaoParcelamentoForm', {}));

export const gerarPrevisoesPorParcelamento = values => async (dispatch) => {
  try {
    const mes = parseIntDecimal(moment(values.periodoCompetencia).format('M'));
    const ano = parseIntDecimal(moment(values.periodoCompetencia).year());

    dispatch(processandoPrevisao(true));
    const { data } = await laris.post(
      `/previsoes/gerarPrevisoesComBaseNoParcelamento/${mes}/${ano}`,
      {},
    );

    const previsoes = data.previsoesGeradas.map(item => ({
      ...item,
      dataDoLancamento: moment(item.dataDoLancamento).format('L'),
    }));

    if (previsoes.length === 0) {
      dispatch(processandoPrevisao(false));
      return createInfoNotification(
        'Não foi encontrado nenhum parcelamento para o periodo selecionado',
      );
    }
    dispatch(showModalGerarPrevisaoParcelamento(false));
    dispatch(getPrevisoesSuccess(previsoes));
    dispatch(processandoPrevisao(false));

    return createSuccessNotification(`Foram geradas ${previsoes.length} previsões`);
  } catch (err) {
    dispatch(processandoPrevisao(false));
    sendErroRequest(err, 'Ocorreu um erro ao gerar as previsões!');
  }
};

export const setItensPrevisao = response => ({
  type: a.PRV_SET_ITENS_PREVISAO,
  payload: response,
});

export const setPrevisaoEdit = response => ({
  type: a.PRV_SET_PREVISAO_EDIT,
  payload: response,
});
export const getPrevisoes = values => async (dispatch) => {
  try {
    const filtro = {
      dataInicial: moment(values.periodoInicial).startOf('day'),
      dataFinal: moment(values.periodoFinal).startOf('day'),
      conta_Id: values.conta ? values.conta : '00000000-0000-0000-0000-000000000000',
      documento: values.documento || '',
      filtrarPorLancamentosNaoGerados: values.filtrarPorLancamentosNaoGerados,
    };
    dispatch(processandoPrevisao(true));

    const { data } = await laris.post(`/previsoes/getPrevisoesComFiltro`, filtro);

    const previsoes = data.map(item => ({
      ...item,
      dataDoLancamento: moment(item.dataDoLancamento).format('L'),
      previsaoEditada: false,
    }));

    dispatch(getPrevisoesSuccess(previsoes));
    dispatch(processandoPrevisao(false));
  } catch (err) {
    dispatch(processandoPrevisao(false));
    sendErroRequest(err, 'Ocorreu um erro ao carregar as previsões!');
  }
};

export const getPrevisaoById = id => async (dispatch) => {
  try {
    dispatch(processandoPrevisao(true));

    const { data } = await laris.get(`/previsoes/${id}`);

    dispatch(setItensPrevisao(data.itensPrevisao));
    dispatch(setPrevisaoEdit(data));

    setTimeout(() => dispatch(processandoPrevisao(false)), 100);
  } catch (err) {
    dispatch(processandoPrevisao(false));
    sendErroRequest(err, 'Ocorreu um erro ao carregar a previsao selecionada!');
  }
};

export const clearPrevisaoEdit = () => (dispatch) => {
  dispatch(setPrevisaoEdit({}));
}

export const deletePrevisao = id => async (dispatch) => {
  try {
    const res = await swal.fire({
      title: 'Confirmar deleção!',
      text: 'Você tem certeza que deseja deletar esse item?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Realmente deseja deletar?',
      cancelButtonText: 'Cancelar',
    });

    if (res.value) {
      const { data } = await laris.delete(`/previsoes/${id}`);

      if (data) {
        const values = {
          periodoInicial: new Date(),
          periodoFinal: new Date(),
          conta: null,
          documento: null,
          filtrarPorLancamentosNaoGerados: false,
        };
        dispatch(getPrevisoes(values));
        createSuccessNotification('Previsão excluído com sucesso!');
      }
    }
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro ao exluir a Previsao!');
  }
};

export const putPrevisao = (previsaoForm, itensPrevisao, previsoes) => async (dispatch) => {
  try {
    let editarLancamentoGerado = false;

    if (previsaoForm.lancamento_Id !== '00000000-0000-0000-0000-000000000000') {
      const res = await swal.fire({
        title: 'Confirmar edição!',
        text: 'Existe lançamento gerado por essa previsão deseja atualizar também?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Realmente deseja atualizar?',
        cancelButtonText: 'Cancelar',
      });

      if (res.value) {
        editarLancamentoGerado = true;
      }
    }
    const novosItensPrevisao = itensPrevisao.map(i => ({
      ...i,
      valorTaxa: formatarNumeroGeral(i.valorTaxa),
    }));
    const prev = {
      ...previsaoForm,
      editarLancamentoGerado,
      itensPrevisao: novosItensPrevisao,
      valorDoLancamento: formatarNumeroGeral(previsaoForm.valorDoLancamento),
    };

    dispatch(processandoPrevisao(true));
    const {
      data: { previsao: newPrevisao },
    } = await laris.put(`/previsoes`, prev);

    const newListPrevisao = previsoes.map((previsao) => {
      if (previsao.id === newPrevisao.id)
        return {
          ...newPrevisao,
          dataDoLancamento: moment(newPrevisao.dataDoLancamento).format('L'),
          previsaoEditada: true,
        };
      return {
        ...previsao,
        previsaoEditada: false,
      };
    });

    dispatch(getPrevisoesSuccess(newListPrevisao));
    dispatch(processandoPrevisao(false));
    createSuccessNotification('Previsao alterada com sucesso!');
    hashHistory.push('/previsoes');
  } catch (err) {
    dispatch(processandoPrevisao(false));
    sendErroRequest(err, 'Ocorreu um erro ao alterar a Previsao!');
  }
};

export const postPrevisao = (previsaoForm, itensPrevisao, previsoes) => async (dispatch) => {
  try {
    const prev = {
      ...previsaoForm,
      itensPrevisao,
      valorDoLancamento: formatarNumeroGeral(previsaoForm.valorDoLancamento),
    };
    dispatch(processandoPrevisao(true));

    const {
      data: { previsao: newPrevisao },
    } = await laris.post(`/previsoes`, prev);

    const newPrev = [
      ...[
        {
          ...newPrevisao,
          dataDoLancamento: moment(newPrevisao.dataDoLancamento).format('L'),
          previsaoEditada: false,
        },
      ],
      ...previsoes,
    ];

    dispatch(getPrevisoesSuccess(newPrev));
    dispatch(processandoPrevisao(false));
    createSuccessNotification('Previsão salva com sucesso!');
    hashHistory.push('/previsoes');
  } catch (err) {
    dispatch(processandoPrevisao(false));
    sendErroRequest(err, 'Ocorreu um erro ao cadastrar a Previsão!');
  }
};

export const bloquearDesbloquearPrevisao = (id, bloquear, previsoes) => async (dispatch) => {
  try {
    if (!bloquear) {
      const willDelete = await swal.fire({
        title: 'Confirmar desbloqueio de previsão!',
        text: 'As alterações realizadas na previsão só serão refletidas nas faturas, se as mesmas forem excluídas e geradas novamente!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Realmente deseja desbloquear?',
        cancelButtonText: 'Bloquear ',
      });

      if (willDelete.isConfirmed) {
        swal.clickCancel;
      }
      else {
        return
      }
    }

    dispatch(processandoPrevisao(true));

    await laris.put(`/previsoes/bloquearDesbloquearPrevisao/${id}/${bloquear}`, {});

    const novaLista = previsoes.map(prev => ({
      ...prev,
      previsaoBloqueada: prev.id === id ? bloquear : prev.previsaoBloqueada,
    }));

    dispatch(getPrevisoesSuccess(novaLista));
    dispatch(processandoPrevisao(false));

  } catch (error) {
    dispatch(processandoPrevisao(false));
    sendErroRequest(
      error,
      `Ocorreu um erro ao ${bloquear ? 'bloquear' : 'desbloquear'} a Previsao!`,
    );
  }
};

export const gerarItensDaPrevisaoActions =
  (values, descricaoDaContasintetica) => async (dispatch) => {
    try {
      const previsaoForm = {
        tipoDespesaReceita: values.tipoDespesaReceita,
        dataDoLancamento: values.dataDoLancamento,
        valorDoLancamento: formatarNumeroGeral(values.valorDoLancamento),
        conta: { id: values.conta.id !== undefined ? values.conta.id : values.conta },
        descricaoContaSintetica: descricaoDaContasintetica,
      };

      if (!previsaoForm.tipoDespesaReceita)
        return createInfoNotification('Informe o Tipo de receita!');

      if (!previsaoForm.valorDoLancamento)
        return createInfoNotification('Informe o valor antes de gerar as previsões!');

      if (!previsaoForm.dataDoLancamento)
        return createInfoNotification('Informe a data do lançamento antes de gerar as previsões!');

      const { data } = await laris.post(`/previsoes/simularItensPrevisao`, previsaoForm);

      dispatch(setItensPrevisao(data));
    } catch (err) {
      sendErroRequest(err, 'Ocorreu um erro ao gerar os itens da previsão!');
    }
  };

export const adicionarItemPrevisao =
  (values, unidades, itensPrevisao, unidadesFiltradas, parametros) => async (dispatch) => {
    try {
      const {
        considereDespesasExtraordinariasParaCalculoDeUnidadesIsentas,
        considereDespesasOrdinariasParaCalculoDeUnidadesIsentas,
      } = parametros;
      const { unidadeId, tipoDespesaReceita, valorDoLancamento } = values;
      const unidadesSelecionadas = unidadesFiltradas.length > 0 ? unidadesFiltradas : unidades;
      const arrayItensPrevisao = [];

      if (!unidadeId)
        return createInfoNotification(
          'Não é possível inserir um item na previsão se não tiver uma  selecionada uma unidade!',
        );

      if (!valorDoLancamento)
        return createInfoNotification(
          'Não é possível inserir um item na previsão se o valor estiver zerado!',
        );

      if (itensPrevisao.length > 0) {
        const existeUnidade = itensPrevisao.find(item => item.unidade.id === unidadeId);
        if (existeUnidade) return createInfoNotification('A unidade selecionada já foi adicionada');
      }

      let filterUnidades = [];
      if (unidadeId === GUID_EMPTY) {
        setItensPrevisao([]);
        filterUnidades = unidadesSelecionadas;
      } else filterUnidades = unidadesSelecionadas.filter(uni => uni.id === unidadeId);

      for (let index = 0; index < filterUnidades.length; index += 1) {
        const unidadeSelecionada = filterUnidades[index];

        const { percentualIsencaoUnidadeIsenta, id, unidadeBasica } = unidadeSelecionada;

        let percentual = 100;
        let valorTaxa = formatarNumeroGeral(valorDoLancamento);

        if (
          !considereDespesasExtraordinariasParaCalculoDeUnidadesIsentas &&
          tipoDespesaReceita === 2 &&
          percentualIsencaoUnidadeIsenta > 0
        ) {
          valorTaxa =
            (formatarNumeroGeral(valorDoLancamento) / 100) * (100 - percentualIsencaoUnidadeIsenta);
          percentual = 100 - percentualIsencaoUnidadeIsenta;
        }
        if (
          !considereDespesasOrdinariasParaCalculoDeUnidadesIsentas &&
          tipoDespesaReceita === 1 &&
          percentualIsencaoUnidadeIsenta > 0
        ) {
          valorTaxa =
            (formatarNumeroGeral(valorDoLancamento) / 100) * (100 - percentualIsencaoUnidadeIsenta);
          percentual = 100 - percentualIsencaoUnidadeIsenta;
        }

        const itemPrevisao = {
          id: newGuid(),
          unidade: {
            id,
            unidadeBasica,
          },
          percentual,
          valorTaxa: valorTaxa || 0,
          valorTaxaFormatado: ` ${formatarValor(valorTaxa, 2).toLocaleString('pt-BR')}`,
          memoriaCalculo: `Valor da previsão R$ ${formatarValor(valorTaxa, 2).toLocaleString('pt-BR') || 0.0
            }`,
          colunaRemover: true,
        };

        if (unidadeId === GUID_EMPTY) arrayItensPrevisao.push(itemPrevisao);
        else if (itensPrevisao.length > 0) {
          dispatch(setItensPrevisao([...itensPrevisao, itemPrevisao]));
        } else dispatch(setItensPrevisao([itemPrevisao]));
      }

      if (unidadeId === GUID_EMPTY) dispatch(setItensPrevisao(arrayItensPrevisao));
    } catch (error) {
      sendErroRequest(error, 'Erro ao processar os itens da previsão!');
    }
  };

export const limparListaItensPrevisao = itensPrevisao => (dispatch) => {
  if (itensPrevisao.length > 0) {
    dispatch(processandoPrevisao(true));
    dispatch(setItensPrevisao([]));
    setTimeout(() => dispatch(processandoPrevisao(false)), 300);
  }
};

export const excluirItemPrevisao = (itemPrevisao, itensPrevisao) => async (dispatch) => {
  try {
    if (itensPrevisao.length >= 1) {
      const novaLista = await itensPrevisao.filter(item => item.id !== itemPrevisao.id);

      dispatch(setItensPrevisao(novaLista));
    }
  } catch (error) {
    sendErroRequest(error, 'Ocorreu um erro inesperado ao tentar excluir o item da previsão!');
  }
};

export const checkPrevisao = (item, listaPrevisao) => async (dispatch) => {
  try {
    if (item.length === 0) return dispatch(getPrevisoesSelecionadas([]));

    const novaLista = await listaPrevisao.map((previsao) => {
      const novaPrevisao = item.find(i => i.id === previsao.id);
      if (!novaPrevisao) return previsao;
      return { ...novaPrevisao, checked: true };
    });
    dispatch(getPrevisoesSelecionadas(novaLista));
  } catch (error) {
    sendErroRequest(error, 'Erro ao selecionar os lançamentos por previsões!');
  }
};

export const gerarLancamentosPrevisao = previsoes => async (dispatch) => {
  try {
    const idsPrevisao = previsoes.filter(item => item.checked).map(item => item.id);

    if (idsPrevisao.length === 0)
      return createInfoNotification(
        'Não é possível gerar os lançamentos sem marcar alguma previsão',
      );

    dispatch(processandoPrevisao(true));

    const { data } = await laris.post(`/lancamentos-previsao`, idsPrevisao);

    dispatch(getPrevisoesSuccess([]));
    dispatch(processandoPrevisao(false));

    createSuccessNotification(`Foram gerados ${data.length} lançamentos com sucesso.`);
  } catch (error) {
    dispatch(processandoPrevisao(false));
    sendErroRequest(
      error,
      'Ocorreu um erro inesperado ao tentar gerar os lançamentos da previsão!',
    );
  }
};

export const buscarPrevisoesPeriodo = periodo => async (dispatch) => {
  try {
    if (!periodo) {
      createInfoNotification('É necessário informar um período de origem!');
      return;
    }
    const periodoSelecionado = moment(periodo).format('YYYYMM');

    dispatch(processandoPrevisao(true));
    const { data } = await laris.get(`/previsoes/periodo/${periodoSelecionado}`);

    const novaLista = data.map(item => ({
      checked: false,
      id: item.id,
      documento: item.documento,
      taxa: item.taxa,
      valorDoLancamentoFormatado: item.valorDoLancamentoFormatado,
      descricaoTipoDespesaReceita: item.descricaoTipoDespesaReceita,
      descricaoTipoLancamentoDespesaReceita: item.descricaoTipoLancamentoDespesaReceita,
    }));
    if (novaLista.length === 0) {
      createInfoNotification('Não foram encontrados previsões!');
      return;
    }

    dispatch(getPrevisoesDaImportacao(novaLista));
    dispatch(processandoPrevisao(false));
  } catch (error) {
    sendErroRequest(error, 'Erro ao buscar os itens da importação!');
    dispatch(processandoPrevisao(false));
  }
};
// setLoading(false);

export const closeAndClear = () => (dispatch) => {
  dispatch(getPrevisoesDaImportacao([]));
  dispatch(processandoPrevisao(false));
};
export const importarPrevisoes = (periodoDestino, previsao) => async (dispatch) => {
  try {
    if (previsao.length <= 0)
      return createInfoNotification('É necessário selecionar as previsões ');
    dispatch(processandoPrevisao(true));
    const periodoSelecionadoDestino = moment(periodoDestino).format('YYYYMM');
    const idsSelecionados = previsao.filter(item => item.checked).map(item => item.id);

    const { data } = await laris.post(
      `/previsoes/clonar-previsoes/${periodoSelecionadoDestino}`,
      idsSelecionados,
    );

    dispatch(processandoPrevisao(false));

    createSuccessNotification(`Foram importadas ${data.length} previsões com sucesso`, 'success');

    dispatch(closeAndClear());
  } catch (error) {
    dispatch(processandoPrevisao(false));
    sendErroRequest(error, 'Erro ao importar as previsões!');
  }
};

export const buscarDescricaoContaSinteticaPrevisao = idContaAnalitica => async (dispatch) => {
  try {
    if (!idContaAnalitica) return '';

    const { data } = await laris.get(`/contas/conta-acima/${idContaAnalitica}`);
    dispatch(setItensPrevisao([]));
    return data.descricao;
  } catch (err) {
    sendErroRequest(err, 'Ocorreu um erro inesperado ao tentar buscar as contas de despesa!');
  }
};
