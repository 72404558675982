import moment from 'moment';

import * as a from '../actionsTypes/actionTypes';
import laris from '../api/laris';
import Util from '../helpers/util';
import { formatJsonExcel, formatarNumeroGeral } from '../utils/Functions';
import {
  createSuccessNotification,
  sendErroRequest,
  createInfoNotification,
  createErrorNotification,
} from '../utils/Notification';
import { adicionarItemRelatorio, processandoRelatorio } from './gerenciadorRelatorioActions';

export const setEstadoInicialRelatorios = () => (dispatch) => {
  dispatch({ type: a.REL_SET_ESTADO_INICIAL, payload: [] });
};

export const setDadosEvolucaoInadimplencia = dados => dispatch =>
  dispatch({
    type: a.REL_SET_DADOS_EVOLUCAO_INADIMPLENCIA,
    payload: dados,
  });

export const setDadosDemonstrativoSaldoConta = dados => dispatch =>
  dispatch({
    type: a.REL_SET_DADOS_DEMONSTRATIVO_SALDO_CONTA,
    payload: dados,
  });
export const emitirRelatorioAdimplencia = values => async (dispatch) => {
  try {
    createSuccessNotification('Foi solicitado a emissao de um relatório! ');

    const { data } = await laris.post(`/relatorios/declaracao-quitacao-condominial`, values);

    if (data.length === 0) {
      return createSuccessNotification('Não existe nada à ser emitido!');
    }
    data.forEach((item) => {
      dispatch(adicionarItemRelatorio(item));
    });
  } catch (err) {
    sendErroRequest(err, 'Ocorreu um erro inesparado na emissão do relatório ');
  }
};

export const emitirRelacaoRecebimentoCondomino = values => async (dispatch) => {
  try {
    const dataInicial = moment(values.dataInicial).format('YYYY-MM-DD');
    const dataFinal = moment(values.dataFinal).format('YYYY-MM-DD');

    if (dataInicial > dataFinal) {
      return createInfoNotification('Data Inicial deve ser menor que Data Final!');
    }
    createSuccessNotification('Requisição de relatório  feita com sucesso!');
    const { data } = await laris.get(
      `/relatorios/relacao-recebimento-condomino/${dataInicial}/${dataFinal}`,
    );

    if (data.length === 0)
      createInfoNotification('Não encontramos nenhum relatório no período informado');

    data.forEach((item) => {
      dispatch(adicionarItemRelatorio(item));
    });
  } catch (err) {
    sendErroRequest(err, 'Ocorreu um erro inesparado na emissão a relação.!');
  }
};

export const emitirRelatorioDemonstrativoFinanceiroAnalitico = values => async (dispatch) => {
  try {
    createSuccessNotification('Relatório solicitado com sucesso!');
    const { data } = await laris.post(`/relatorios/demonstrativo-financeiro-analitico`, values);
    if (data.length === 0) createInfoNotification('Não existe nada à ser emitido!');
    else
      data.forEach((item) => {
        dispatch(adicionarItemRelatorio(item));
      });
  } catch (err) {
    sendErroRequest(
      err,
      'Ocorreu um erro inesparado na emissão do relatório Demonstrativo Financeiro Analitico. Verifique os dados e tente novamente!',
    );
  }
};

export const emitirRelatorioPrevisaoUnidades = values => async (dispatch) => {
  try {
    createSuccessNotification('Requisição de relatório do Previsão de Unidades feita com sucesso!');

    const { data } = await laris.post(`/relatorios/previsao-unidades`, values);
    setTimeout(() => {
      if (data.length === 0) createInfoNotification('Não existe nada à ser emitido!');
      else
        data.forEach((item) => {
          dispatch(adicionarItemRelatorio(item));
        });
    }, 2000);
  } catch (error) {
    sendErroRequest(
      error,
      'Ocorreu um erro inesparado na emissão do relatorio de Previsão de Unidades. Verifique os dados e tente novamente!',
    );
  }
};

export const emitirRelatorioDeRecebimentosDeTaxas = values => async (dispatch) => {
  try {
    createSuccessNotification(
      'Requisição de relatório de recebimentos de taxas feita com sucesso!',
    );
    const recebimentoForm = {
      dataInicial: values.dataInicial,
      dataFinal: values.dataFinal,
      unidade_Id: values.unidade_Id || '00000000-0000-0000-0000-000000000000',
      observacao: values.observacao || '',
      metodoRelatorio: values.metodoRelatorio,
    };
    const { data } = await laris.post(`/relatorios/recebimentos-taxas`, recebimentoForm);
    if (data.length === 0) createInfoNotification('Não existe nada à ser emitido!');

    data.forEach((item) => {
      dispatch(adicionarItemRelatorio(item));
    });
  } catch (err) {
    sendErroRequest(
      err,
      'Ocorreu um erro inesparado na emissão do relatório de Recebimentos de taxas. Verifique os dados e tente novamente!',
    );
  }
};

export const emitirRelacaoBoletosGerados =
  (values, tipoImpressao, listaBoletoSelecionados, periodoSelecionado) => async (dispatch) => {
    try {
      dispatch(processandoRelatorio(true));
      if (tipoImpressao === 'excel' || tipoImpressao === 'pdf') {
        if (listaBoletoSelecionados === null) {
          dispatch(processandoRelatorio(false));
          return createInfoNotification('É necessário selecionar boletos para impressão!');
        }
      }
      let filtro = {};
      if (values !== null) {
        filtro = {
          ...(values.tipoEmissao && { tipoEmissao: values.tipoEmissao }),
          ...(tipoImpressao && { tipoImpressao }),
          ...(values.periodoInicial && { periodoInicial: values.periodoInicial }),
          ...(values.periodoFinal && { periodoFinal: values.periodoFinal }),
          ...(values.periodoVencimentoInicial && {
            vencimentoInicial: values.periodoVencimentoInicial,
          }),
          ...(values.periodoVencimentoFinal && { vencimentoFinal: values.periodoVencimentoFinal }),
          ...(listaBoletoSelecionados && { boletosGerados: listaBoletoSelecionados }),
        };

        switch (values.tipoFiltro) {
          case 1:
            filtro = { ...filtro, unidadeId: values.filtro };
            break;
          case 2:
            filtro = { ...filtro, condominoId: values.filtro };
            break;
          case 3:
            filtro = { ...filtro, proprietarioId: values.filtro };
            break;
          default:
            filtro = { ...filtro };
        }

        createSuccessNotification('Requisição feita com sucesso!');

        const { data } = await laris.post(`/relatorios/relacao-boletos-gerados`, filtro);
        dispatch(processandoRelatorio(false));
        if (data.length === 0) return createInfoNotification('Não existe  boletos gerados!');

        return data;
      }

      if (tipoImpressao === 'pdf') {
        dispatch(processandoRelatorio(false));
        filtro = {
          ...(tipoImpressao && { tipoImpressao }),
          ...(periodoSelecionado.periodoInicial && {
            periodoInicial: periodoSelecionado.periodoInicial,
          }),
          ...(periodoSelecionado.periodoFinal && { periodoFinal: periodoSelecionado.periodoFinal }),
          ...(listaBoletoSelecionados && { boletosGerados: listaBoletoSelecionados }),
        };
        createSuccessNotification(
          'Requisição feita com sucesso ,Aguarde até que seja gerado o Pdf',
        );
        const { data } = await laris.post(`/relatorios/relacao-boletos-gerados`, filtro);

        data.forEach((item) => {
          dispatch(adicionarItemRelatorio(item));
        });
        dispatch(processandoRelatorio(false));
      }

      if (tipoImpressao === 'excel') {
        dispatch(processandoRelatorio(false));
        const rowsValues = [];
        let rowInicial = 5;
        for (let index = 0; index < listaBoletoSelecionados.length; index += 1) {
          const boletoSelecionado = listaBoletoSelecionados[index];

          rowInicial += 1;
          const linha = {
            row: rowInicial,
            action: 2,
            columns: [
              {
                col: 2,
                value: boletoSelecionado.unidade,
              },
              {
                col: 3,
                value: boletoSelecionado.condomino,
              },
              {
                col: 4,
                value: boletoSelecionado.referencia,
              },

              {
                col: 5,
                value: boletoSelecionado.dataDoVencimentoFormatada,
              },
              {
                col: 6,
                value: boletoSelecionado.valorDaTaxaFormatada,
              },
              {
                col: 7,
                value: boletoSelecionado.valorDoFundoDeReserva,
              },
              {
                col: 8,
                value: boletoSelecionado.valorDaFaturaFormatada,
              },
              {
                col: 9,
                value:
                  boletoSelecionado.dataDoPagamentoFormatada == null
                    ? ''
                    : boletoSelecionado.dataDoPagamentoFormatada,
              },

              {
                col: 10,
                value: boletoSelecionado.valorPagoFormatada,
              },
            ],
          };
          rowsValues.push(linha);
        }

        const json = formatJsonExcel(rowsValues);
        createSuccessNotification(
          'Requisição feita com sucesso ,Aguarde até que seja gerado a Planilha',
        );
        const { data } = await laris.post('/relatorios/post-export-boletos-gerados', json);

        const item = {
          id: data.chave,
          dataEmissao: new Date(),
          nome: 'Planilha_Boletos_Gerados',
          url: data.retorno,
        };

        dispatch(adicionarItemRelatorio(item));
        dispatch(processandoRelatorio(false));
      }
    } catch (err) {
      dispatch(processandoRelatorio(false));
      sendErroRequest(err, 'Ocorreu um erro inesparado na Relação de Boletos Gerados!');
    }
  };

export const emitirRelatorioMovimentacaoFinanceira = values => async (dispatch) => {
  try {
    const filtro = {
      ...(values.periodo && { periodo: values.periodo }),
      ...(values.contaId && { conta_id: values.contaId }),
      ...(values.contador && { contador: values.contador }),
    };
    createInfoNotification('Requisição de relatório de movimentação financeira feita com sucesso!');

    const { data } = await laris.post(`/relatorios/movimentacao-financeira`, filtro);

    if (data.length === 0) createInfoNotification('Não existe nada à ser emitido!');

    data.forEach((item) => {
      dispatch(adicionarItemRelatorio(item));
    });
  } catch (error) {
    sendErroRequest(
      error,
      'Ocorreu um erro inesparado na emissão do relatorio de Movimentação Financeira. Verifique os dados e tente novamente!',
    );
  }
};

export const emitirRelatorioFaturasAcordadas = values => async (dispatch) => {
  try {
    const params = {
      ...(values.acordosQuitados !== null && { acordosQuitados: values.acordosQuitados }),
      ...(values.dataQuitacaoFinal && { dataQuitacaoFinal: values.dataQuitacaoFinal }),
      ...(values.dataQuitacaoInicial && { dataQuitacaoInicial: values.dataQuitacaoInicial }),
      ...(values.unidadeId && { unidadeId: values.unidadeId }),
    };

    const { data } = await laris.post(`/relatorios/faturas-acordadas`, params);

    if (data.length === 0) createInfoNotification('Não existe nada à ser emitido!');

    data.forEach((item) => {
      dispatch(adicionarItemRelatorio(item));
    });
  } catch (error) {
    sendErroRequest(
      error,
      'Ocorreu um erro inesparado na emissão do relatorio de faturas acordadas. Verifique os dados e tente novamente!',
    );
  }
};

export const buscarDadosEvolucaoInadimplencia = values => async (dispatch) => {
  try {
    if (values.periodoInicial === null || values.periodoFinal === null)
      return sendErroRequest(
        'Evolução Inadimplência',
        'É necessario Informar o Período Inicial e o Período Final!',
      );
    const filtro = {
      ...values,
      periodoInicial:
        values.periodo === 1
          ? moment(values.periodoInicial).startOf('day')
          : moment(values.periodoInicial).startOf('month'),
      periodoFinal:
        values.periodo === 1
          ? moment(values.periodoFinal).startOf('day')
          : moment(values.periodoFinal).endOf('month').startOf('day'),
    };

    const { data } = await laris.post(`/relatorios/evolucao-inadimplencia/buscarDados`, filtro);

    if (data.length === 0)
      return createInfoNotification('Não existem dados à serem emitidos no período selecionado!');

    const descricoes = data.map(item => item.periodo);

    const valores = data.map(item => item.valorMensal);

    const dadosGrafico = {
      labels: descricoes,
      values: valores,
    };
    dispatch(setDadosEvolucaoInadimplencia(dadosGrafico));
  } catch (err) {
    sendErroRequest(
      err,
      'Ocorreu um erro inesparado na busca de dados para demonstração do gráfico de evolução de inadimplência. Verifique os dados e tente novamente!',
    );
  }
};

export const emitirGraficoEvolucaoInadimplencia = values => async (dispatch) => {
  try {
    createInfoNotification('Solicitação em Andamento!');
    const filtro = {
      ...values,
      periodoInicial:
        values.periodo === 1
          ? moment(values.periodoInicial).startOf('day')
          : moment(values.periodoInicial).startOf('month'),
      periodoFinal:
        values.periodo === 1
          ? moment(values.periodoFinal).startOf('day')
          : moment(values.periodoFinal).endOf('month').startOf('day'),
    };

    const { data } = await laris.post(`/relatorios/evolucao-inadimplencia/emitirGrafico`, filtro);

    if (data.length === 0) createInfoNotification('Não existe nada à ser emitido!');

    data.forEach((item) => {
      dispatch(adicionarItemRelatorio(item));
    });
  } catch (error) {
    sendErroRequest(
      error,
      'Ocorreu um erro inesparado na emissão do gráfico . Verifique os dados e tente novamente!',
    );
  }
};

export const emitirRelatorioEvolucaoInadimplencia = values => async (dispatch) => {
  try {
    createInfoNotification('Solicitação em Andamento!');
    const filtro = {
      ...values,
      periodoInicial:
        values.periodo === 1
          ? moment(values.periodoInicial).startOf('day')
          : moment(values.periodoInicial).startOf('month'),
      periodoFinal:
        values.periodo === 1
          ? moment(values.periodoFinal).startOf('day')
          : moment(values.periodoFinal).endOf('month').startOf('day'),
    };

    const { data } = await laris.post(`/relatorios/evolucao-inadimplencia/emitirRelatorio`, filtro);

    if (data.length === 0) createInfoNotification('Não existe nada à ser emitido!');
    data.forEach((item) => {
      dispatch(adicionarItemRelatorio(item));
    });
  } catch (error) {
    sendErroRequest(
      error,
      'Ocorreu um erro inesparado na emissão do relatório . Verifique os dados e tente novamente!',
    );
  }
};

export const emitirRelatorioCobranca = values => async (dispatch) => {
  try {
    if (values.dataVencimentoFinal < values.dataVencimentoInicial)
      return createErrorNotification('Data final tem que ser maior que a data inicial!');
    createSuccessNotification('Requisição de relatório de cobrança feita com Sucesso!');
    const params = {
      ...values,
      ...(values.dataCalculo && {
        dataCalculo: moment(values.dataCalculo).format('YYYY-MM-DD'),
      }),
      ...(values.dataVencimentoInicial && {
        dataVencimentoInicial: moment(values.dataVencimentoInicial).format('YYYY-MM-DD'),
      }),
      ...(values.dataVencimentoFinal && {
        dataVencimentoFinal: moment(values.dataVencimentoFinal).format('YYYY-MM-DD'),
      }),
      ...(values.unidade_Id && { unidade_Id: values.unidade_Id }),
      ...(values.condomino_Id && { condomino_Id: values.condomino_Id }),
      ...(values.proprietario_Id && { proprietario_Id: values.proprietario_Id }),
      ...(values.indexador && { indexador: values.indexador }),
    };
    const { data } = await laris.get(`/relatorios/cobranca`, { params });

    if (data.length === 0) createInfoNotification('Não existe nada à ser emitido!');

    data.forEach((item) => {
      dispatch(adicionarItemRelatorio(item));
    });
  } catch (error) {
    sendErroRequest(
      error,
      'Ocorreu um erro inesparado na emissão do relatório de Cobrança. Verifique os dados e tente novamente!',
    );
  }
};

export const emitirRelatorioBalanceteSintetico = values => async (dispatch) => {
  try {
    if (values.periodoInicial > values.periodoFinal)
      createInfoNotification(
        'O periodo inicial não pode ser maior que o periodo final. Verifique os dados e tente novamente!',
      );

    createSuccessNotification('Requisição de relatório do balancete sintético feita com sucesso!');

    const { data } = await laris.post(`/relatorios/balancete-sintetico`, values);

    if (data.length === 0) createSuccessNotification('Não existe nada à ser emitido!');
    else {
      data.forEach((item) => {
        dispatch(adicionarItemRelatorio(item));
      });
    }
  } catch (error) {
    sendErroRequest(
      error,
      'Ocorreu um erro inesparado na emissão do relatorio de Balancete Sintético. Verifique os dados e tente novamente!',
    );
  }
};

export const emitirRelatorioDeUnidades = values => async (dispatch) => {
  try {
    createSuccessNotification('Emissão do Relatório de Unidades feita com sucesso!');

    const params = {
      condomino_id:
        values && values.condomino_id !== null && values.condomino_id !== ''
          ? values.condomino_id
          : Util.guidEmpty(),
      proprietario_id:
        values && values.proprietario_id !== null && values.proprietario_Id !== ''
          ? values.proprietario_id
          : Util.guidEmpty(),
    };

    const { data } = await laris.post(`/relatorios/relatorio-unidades`, params);

    if (data.length === 0)
      return sendErroRequest('Relatorio Unidade', 'Não existe nada à ser emitido!');

    data.forEach((item) => {
      dispatch(adicionarItemRelatorio(item));
    });
  } catch (err) {
    sendErroRequest(
      err,
      'Ocorreu um erro inesparado na emissão do relatorio de Relatório de Unidades. Verifique os dados e tente novamente!',
    );
  }
};

export const emitirRelatorioPatrimonial = values => async (dispatch) => {
  try {
    let filtro = {
      tipo: values && values.tipo !== null && values.tipo !== '' ? values.tipo : 0,
      natureza: values && values.natureza !== null && values.natureza !== '' ? values.natureza : 0,
    };

    if (values.periodoInicial && values.periodoFinal)
      filtro = {
        ...filtro,
        dataAquisicaoInicial: moment(values.periodoInicial).startOf('day'),
        dateAquisicaoFinal: moment(values.periodoFinal).startOf('day'),
      };

    createSuccessNotification('Emissão do relatório patrimonial feita com sucesso!');

    const { data } = await laris.post(`/relatorios/relatorio-patrimonio`, filtro);

    if (data.length === 0)
      return sendErroRequest('Relatório Patrimonial', 'Não existe nada à ser emitido!');

    data.forEach((item) => {
      dispatch(adicionarItemRelatorio(item));
    });
  } catch (error) {
    sendErroRequest(
      error,
      'Ocorreu um erro inesparado na emissão do relatorio de Relatório Patrimonial. Verifique os dados e tente novamente!',
    );
  }
};

export const emitirRelatorioDeConsumo = values => async (dispatch) => {
  try {
    if (values.unidadeId === null || values.unidadeId === '')
      return createErrorNotification('É necessário selecionar uma unidade!');
    if (values.tipoDoConsumo === null || values.tipoDoConsumo === '')
      return createErrorNotification('É necessário selecionar um tipo de consumo!');
    if (values.periodoInicial === null || values.periodoFinal === null)
      return createErrorNotification('É necessário selecionar um período!');

    const filtro = {
      dataInicial: values.periodoInicial,
      dataFinal: values.periodoFinal,
      unidadeId: values.unidadeId,
      tipoDoConsumo: values.tipoDoConsumo,
    };

    const { data } = await laris.post(`/relatorios/relatorio-leituras-consumo`, filtro);
    if (data.length === 0) return createErrorNotification('Não existe nada à ser emitido!');

    data.forEach((item) => {
      dispatch(adicionarItemRelatorio(item));
    });
  } catch (err) {
    sendErroRequest(
      err,
      'Ocorreu um erro inesparado na emissão do relatorio de Leituras de Consumo. Verifique os dados e tente novamente!',
    );
  }
};

export const emitirProtocoloRepassePatrimonio = values => async (dispatch) => {
  try {
    const chaveInfo = 'Repasse de Patrimonio';
    const msgInfo = 'Preencha todos os campos para prosseguir!';

    if (!values) return sendErroRequest(chaveInfo, msgInfo);
    if (!values.dataRepasse) return sendErroRequest(chaveInfo, msgInfo);
    if (!values.idNovoSindico) return sendErroRequest(chaveInfo, msgInfo);

    createSuccessNotification(
      'Requisição de relatório do Protocolo de Repasse Patrimonio feita com sucesso!',
    );

    const { data } = await laris.post(`/relatorios/protocolo-repasse-patrimonio`, values);

    if (data.length === 0)
      return sendErroRequest(
        'Protocolo de Repasse de Patrimonio',
        'Não existe nada à ser emitido!',
      );

    data.forEach((item) => {
      dispatch(adicionarItemRelatorio(item));
    });
  } catch (error) {
    sendErroRequest(
      error,
      'Ocorreu um erro inesparado na emissão do relatorio de Protocolo de Repasse Patrimonio. Verifique os dados e tente novamente!',
    );
  }
};

export const emitirProtocoloEntregaTaxas = () => async (dispatch) => {
  try {
    createSuccessNotification(
      'Requisição de relatório do Protocolo de Entrega de Taxas feita com sucesso!',
    );

    const { data } = await laris.get(`/relatorios/protocolo-entregas-taxas`);
    if (data.length === 0)
      return sendErroRequest('Protocolo de Entrega de Dados', 'Não existe nada à ser emitido!');

    data.forEach((item) => {
      dispatch(adicionarItemRelatorio(item));
    });
  } catch (err) {
    sendErroRequest(
      err,
      'Ocorreu um erro inesparado na emissão do relatorio de Protocolo de Entrega de Taxas. Verifique os dados e tente novamente!',
    );
  }
};
export const emitirRelatorioListagemCondominos = () => async (dispatch) => {
  try {
    createSuccessNotification(
      'Requisição de relatório de listagem de condominos feita com sucesso!',
    );

    const { data } = await laris.get(`/relatorios/listagem-condominos`);
    if (data.length === 0) return createInfoNotification('Não existe nada à ser emitido!');

    data.forEach((item) => {
      dispatch(adicionarItemRelatorio(item));
    });
  } catch (err) {
    sendErroRequest(
      err,
      'Ocorreu um erro inesparado na emissão do relatorio de listagem de condominos. Verifique os dados e tente novamente!',
    );
  }
};

export const emitirRelatorioListaPresenca = values => async (dispatch) => {
  try {
    createSuccessNotification('Requisição de relatório do Lista de Presença feita com sucesso!');

    const { data } = await laris.get(`/relatorios/lista-presenca/${values.titulo}`);

    if (data.length === 0)
      return sendErroRequest('Lista de Presença', 'Não existe nada à ser emitido!');

    data.forEach((item) => {
      dispatch(adicionarItemRelatorio(item));
    });
  } catch (err) {
    sendErroRequest(
      err,
      'Ocorreu um erro inesparado na emissão do relatorio de Lista de Presença. Verifique os dados e tente novamente!',
    );
  }
};

export const buscarDadosDemonstrativoSaldoConta = values => async (dispatch) => {
  try {
    if (values.tipoPeriodoSelecionado === 3) {
      if (!values.periodoFinal || !values.periodoInicial)
        return createInfoNotification(
          'Não é possível emitir o relatorio sem informar os períodos incial e final.',
        );

      if (values.periodoFinal - values.periodoInicial > 3)
        return createInfoNotification(
          'Não é possível emitir o relatorio com mais de 3 anos de saldos.',
        );
    } else {
      if (!values.periodoFinal || !values.periodoFinal)
        return createInfoNotification(
          'Não é possível emitir o relatorio sem informar os períodos incial e final.',
        );

      if (moment(values.periodoFinal).month() - moment(values.periodoInicial).month() > 12)
        return createInfoNotification(
          'Não é possível emitir o relatorio com mais de 12 meses de saldos.',
        );
    }

    if (!values.contaId)
      return createInfoNotification('Não é possível emitir o relatorio sem informar a conta.');

    const demonstrativoSaldoContaForm = {
      periodoInicial: values.tipoPeriodoSelecionado !== 3 ? values.periodoInicial : undefined,
      periodoFinal: values.tipoPeriodoSelecionado !== 3 ? values.periodoFinal : undefined,
      anoInicial: values.tipoPeriodoSelecionado === 3 ? values.periodoInicial : undefined,
      anoFinal: values.tipoPeriodoSelecionado === 3 ? values.periodoFinal : undefined,
      conta_Id: values.contaId,
      tipoPeriodo: values.tipoPeriodoSelecionado,
      tipoGrafico: values.tipoGrafico,
    };

    const { data } = await laris.post(
      `/relatorios/demonstrativo-saldo-conta/buscarDados`,
      demonstrativoSaldoContaForm,
    );

    if (data.length === 0)
      return createInfoNotification(
        'Não existem dados da conta à serem emitidos no período selecionado!',
      );

    const dadosGrafico = {
      labels: data.map(item => item.periodo),
      values: data.map(item => item.saldoDaConta),
    };
    dispatch(setDadosDemonstrativoSaldoConta(dadosGrafico));
  } catch (err) {
    sendErroRequest(
      err,
      'Ocorreu um erro inesparado na busca de dados para demonstração do gráfico de Demonstrativo de Saldo por Conta. Verifique os dados e tente novamente!',
    );
  }
};

export const emitirGraficoDemonstrativoSaldoConta = values => async (dispatch) => {
  try {
    createSuccessNotification('Requisição de relatório feita com sucesso!');

    const demonstrativoSaldoContaForm = {
      periodoInicial: values.tipoPeriodoSelecionado !== 3 ? values.periodoInicial : undefined,
      periodoFinal: values.tipoPeriodoSelecionado !== 3 ? values.periodoFinal : undefined,
      anoInicial: values.tipoPeriodoSelecionado === 3 ? values.periodoInicial : undefined,
      anoFinal: values.tipoPeriodoSelecionado === 3 ? values.periodoFinal : undefined,
      conta_Id: values.contaId,
      tipoPeriodo: values.tipoPeriodoSelecionado,
      tipoGrafico: values.tipoGrafico,
    };
    const { data } = await laris.post(
      `/relatorios/demonstrativo-saldo-conta/emitirGrafico`,
      demonstrativoSaldoContaForm,
    );
    if (data.length === 0) return createInfoNotification('Não existe nada à ser emitido!');

    data.forEach((item) => {
      dispatch(adicionarItemRelatorio(item));
    });
  } catch (error) {
    sendErroRequest(
      error,
      'Ocorreu um erro inesparado na emissão do relatorio de Demonstrativo de Saldo por Conta. Verifique os dados e tente novamente!',
    );
  }
};

export const emitirRelatorioExtratoContas = values => async (dispatch) => {
  try {
    if (values.contas.length <= 0)
      return createInfoNotification('É necessário adicionar uma conta!');
    createSuccessNotification('Requisição de relatório feita com sucesso!');
    const filtro = {
      periodoInicial: moment(values.periodoInicial).startOf('day'),
      periodoFinal: moment(values.periodoFinal).startOf('day'),
      contas_Id: values.contas.map(item => item.id),
    };

    const { data } = await laris.post(`/relatorios/extrato-contas`, filtro);

    if (data.length === 0) return createInfoNotification('Não existe nada à ser emitido!');

    data.forEach((item) => {
      dispatch(adicionarItemRelatorio(item));
    });
  } catch (err) {
    sendErroRequest(
      err,
      'Ocorreu um erro inesparado na emissão do relatorio de Extrato de contas. Verifique os dados e tente novamente!',
    );
  }
};

export const emitirRelatorioDemonstrativoDespesas = values => async (dispatch) => {
  try {
    createSuccessNotification('Requisição de relatório feita com sucesso!');

    const filtro = {
      ...(values.periodo && { periodo: values.periodo }),
      ...(values.fundoReservaMaiorValor && {
        fundoReservaMaiorValor: formatarNumeroGeral(values.fundoReservaMaiorValor),
      }),
      ...(values.fundoReservaMenorValor && {
        fundoReservaMenorValor: formatarNumeroGeral(values.fundoReservaMenorValor),
      }),

      ...(values.taxaMaiorValor && {
        taxaMaiorValor: formatarNumeroGeral(values.taxaMaiorValor),
      }),
      ...(values.taxaMenorValor && {
        taxaMenorValor: formatarNumeroGeral(values.taxaMenorValor),
      }),

      ...(values.unidadeMaiorValor && {
        unidadeMaiorValor: values.unidadeMaiorValor,
      }),

      ...(values.unidadeMenorValor && {
        unidadeMenorValor: values.unidadeMenorValor,
      }),
    };
    const { data } = await laris.post(`/relatorios/demonstrativo-despesas`, filtro);
    if (data.length === 0) return createInfoNotification('Não existe nada à ser emitido!');

    data.forEach((item) => {
      dispatch(adicionarItemRelatorio(item));
    });
  } catch (error) {
    sendErroRequest(
      error,
      'Ocorreu um erro inesparado na emissão do relatorio de Demonstrativo de Despesas. Verifique os dados e tente novamente!',
    );
  }
};

export const emitirRelatorioDespesaRateada = values => async (dispatch) => {
  try {
    createSuccessNotification('Requisição de relatório feita com sucesso!');
    const periodo = moment(values.periodo, 'DD/MM/YYYY').format('YYYYMM');
    const { modeloLayout } = values;
    const { data } = await laris.get(
      `/relatorios/demonstrativo-despesa-rateada/${periodo}/${modeloLayout}`,
      `${modeloLayout}`,
    );
    if (data.length === 0)
      return createInfoNotification('Não encontramos Despesas no período informado');

    data.forEach((item) => {
      dispatch(adicionarItemRelatorio(item));
    });
  } catch (err) {
    sendErroRequest(
      err,
      'Ocorreu um erro inesparado na emissão do relatório Demonstrativo Despesas Rateadas. Verifique os dados e tente novamente!',
    );
  }
};

export const emitirRelatorioRateioDeContas = values => async (dispatch) => {
  try {
    const { taxa, value } = values;

    if (taxa.length > 3)
      return sendErroRequest(
        'Rateio de Contas',
        'Selecione no máximo 3 taxas para emitir o relatório.',
      );

    const filtro = {
      periodo: value.periodo,
      titulo: value.titulo,
      unidade_Id: value.unidade === '' ? '00000000-0000-0000-0000-000000000000' : value.unidade,
      taxas_Id: taxa.map(item => item.id),
    };

    const { data } = await laris.post(`/relatorios/rateio-contas`, filtro);
    if (data.length === 0) {
      return sendErroRequest('Rateio de Contas', 'Não existe nada à ser emitido!');
    }
    data.forEach((item) => {
      dispatch(adicionarItemRelatorio(item));
    });
  } catch (error) {
    sendErroRequest(
      error,
      'Ocorreu um erro inesparado na emissão do relatorio de Rateio de Contas. Verifique os dados e tente novamente!',
    );
  }
};

export const emitirRelatorioBorderoArrecadacao = values => async (dispatch) => {
  try {
    const emissao = { periodo: values.periodo, titulo: values.titulo, unidade_Id: values.unidade };

    createSuccessNotification('Emissão de Relatório solicitada com sucesso!');
    const { data } = await laris.post(`/relatorios/bordero-arrecadacao`, emissao);

    if (data.length === 0)
      return createInfoNotification('Sem registros para o relatório de Borderô de Arrecadação');

    data.forEach((item) => {
      dispatch(adicionarItemRelatorio(item));
    });
  } catch (err) {
    sendErroRequest(
      err,
      'Ocorreu um erro inesparado na emissão do relatório Borderô de Arrecadação. Verifique os dados e tente novamente!',
    );
  }
};

export const emitirRelatorioContasRateadas = values => async (dispatch) => {
  try {
    const filtro = {
      periodo: values.periodo,
      unidadesId: values.unidades.map(item => item.id),
    };
    createInfoNotification('Relatório emitido com sucesso!');

    const { data } = await laris.post(`/relatorios/contas-rateadas`, filtro);

    if (data.length === 0) {
      return createInfoNotification('Não existe nada à ser emitido!');
    }

    data.forEach((item) => {
      dispatch(adicionarItemRelatorio(item));
    });
  } catch (error) {
    sendErroRequest(
      error,
      'Ocorreu um erro inesparado na emissão do relatorio de contas rateadas. Verifique os dados e tente novamente!',
    );
  }
};

export const emitirRelatorioEspelhoLeituraConsumo = values => async (dispatch) => {
  try {
    if (!values)
      return createErrorNotification(
        'É necessário que selecione o tipo de consumo para a emissão do relatório!',
      );

    if (values.tipoConsumo === '')
      return createErrorNotification(
        'É necessário que selecione o tipo de consumo para a emissão do relatório!',
      );
    createSuccessNotification('Relatório solicitado com sucesso!');
    const { data } = await laris.get(
      `/relatorios/espelho-leitura-consumo/${values.tipoConsumo}/${values.imprimirLeituraConsumoAnteriorAtual}`,
    );
    if (data.length === 0) return createInfoNotification('Não existe nada à ser emitido!');

    data.forEach((item) => {
      dispatch(adicionarItemRelatorio(item));
    });
  } catch (error) {
    sendErroRequest(
      error,
      'Ocorreu um erro inesparado na emissão do relatorio de Espelho de Leitura de Consumo. Verifique os dados e tente novamente!',
    );
  }
};

export const emitirRelatorioFaturasEnviadasPorEmail = values => async (dispatch) => {
  try {
    if (values.PeriodoInicial > values.PeriodoFinal)
      return createInfoNotification('Data final não pode ser menor que data inicial!');

    if (!values) return createInfoNotification('Preencha o periodo antes de continuar!');

    const filtro = {
      periodoInicial: moment(values.PeriodoInicial).startOf('day'),
      periodoFinal: moment(values.PeriodoFinal).startOf('day'),
      tipoEmissao: values.TipoEmissao,
    };
    createSuccessNotification('Relatório solicitado com Sucesso');
    const { data } = await laris.post(`/relatorios/faturas-enviadas-email`, filtro);

    if (data.length === 0) return createInfoNotification('Não existe nada à ser emitido!');

    data.forEach((item) => {
      dispatch(adicionarItemRelatorio(item));
    });
  } catch (err) {
    sendErroRequest(
      err,
      'Ocorreu um erro inesparado na emissão do relatorio de Relatório de Faturas enviadas por email. Verifique os dados e tente novamente!',
    );
  }
};

export const emitirRelatorioFichaCondominio = () => async (dispatch) => {
  try {
    const { data } = await laris.get(`/relatorios/ficha-condominio`);
    if (data.length === 0)
      return sendErroRequest('Ficha do Condomínio', 'Não existe nada à ser emitido!');

    data.forEach((item) => {
      dispatch(adicionarItemRelatorio(item));
    });
  } catch (error) {
    sendErroRequest(
      error,
      'Ocorreu um erro inesparado na emissão do relatorio de Ficha do condominio. Verifique os dados e tente novamente!',
    );
  }
};

export const emitirRelatorioLancamentosDespesasReceitas = values => async (dispatch) => {
  try {
    if (!values)
      return createErrorNotification('Preencha o periodo para prosseguir com a consulta');

    if (!values.dataInicial || !values.dataFinal)
      return createErrorNotification('Preencha o periodo para prosseguir com a consulta');

    const filtro = {
      periodoInicial: values.dataInicial,
      periodoFinal: values.dataFinal,
    };

    const { data } = await laris.post(`/relatorios/lancamentos-despesas-receitas`, filtro);

    if (data.length === 0) return createInfoNotification('Não existe nada à ser emitido!');

    data.forEach((item) => {
      dispatch(adicionarItemRelatorio(item));
    });
  } catch (error) {
    sendErroRequest(
      error,
      'Ocorreu um erro inesparado na emissão do relatorio de Lançamento de Despesas e Receitas. Verifique os dados e tente novamente!',
    );
  }
};
export const emitirRelatorioAcordo = values => async (dispatch) => {
  try {
    if (values.dataInicioPeriodo > values.dataFimPeriodo) {
      return createInfoNotification('Data Inicial deve ser menor que a Data Final!');
    }

    const params = {
      ...(values.dataInicioPeriodo && {
        dataInicioPeriodo: values.dataInicioPeriodo,
      }),
      ...(values.dataFimPeriodo && {
        dataFimPeriodo: values.dataFimPeriodo,
      }),
      ...(values.unidadeId && { unidade_Id: values.unidadeId }),
      ...(values.statusId && { status_Id: values.statusId }),
      ...(values.escritorioCobrancaId && { escritorioCobranca_Id: values.escritorioCobrancaId }),
    };

    const res = await laris.post(`/relatorios/relatorio-acordo`, params);
    if (res.data.length === 0)
      createInfoNotification('Não encontramos Acordos no período informado');
    else
      res.data.forEach((item) => {
        createInfoNotification('Requisição de relatório  feita com sucesso!');
        dispatch(adicionarItemRelatorio(item));
      });
  } catch (err) {
    sendErroRequest(
      err,
      'Ocorreu um erro inesperado na emissão a relação. Verifique os dados e tente novamente!',
    );
  }
};

export const emitirRelatorioInadimplencia = values => async (dispatch) => {
  try {
    if (values.dataVencimentoInicial > values.dataVencimentoFinal) {
      return createErrorNotification('Error', 'Data Inicial deve ser menor que Data Final!');
    }
    const params = {
      ...(values.dataVencimentoInicial && {
        dataVencimentoInicial: moment(values.dataVencimentoInicial).format('YYYY-MM-DD'),
      }),
      ...(values.dataVencimentoFinal && {
        dataVencimentoFinal: moment(values.dataVencimentoFinal).format('YYYY-MM-DD'),
      }),
      ...(values.unidadesId && { unidade_Id: values.unidadesId }),
      ...(values.condominoId && { condomino_Id: values.condominoId }),
      ...(values.proprietarioId && { proprietario_Id: values.proprietarioId }),
      ...(values.checked && { mostrarInadimplencia: values.checked }),
      ...(values.observacao && { observacao: values.observacao }),
    };
    createInfoNotification('Requisição de relatório de inadimplência feita com sucesso!');
    const res = await laris.post(`/relatorios/inadimplencia`, params);
    if (res.data.length === 0)
      createInfoNotification('Não encontramos nenhum relatório no período informado');
    else
      res.data.forEach((item) => {
        dispatch(adicionarItemRelatorio(item));
      });
  } catch (err) {
    sendErroRequest(
      err,
      'Ocorreu um erro inesparado na emissão a relação. Verifique os dados e tente novamente!',
    );
  }
};

export const emitirRelatorioConciliacaoContas = values => async (dispatch) => {
  try {
    createSuccessNotification('Requisição de relatório feita com sucesso!');
    const { data } = await laris.post(`/relatorios/conciliacao-contas`, values);

    if (data.length === 0) {
      createInfoNotification('Não existe nada à ser emitido!');
    }

    data.forEach((item) => {
      dispatch(adicionarItemRelatorio(item));
    });
  } catch (err) {
    sendErroRequest(
      err,
      'Ocorreu um erro inesparado na emissão do relatorio de Conciliação de Contas. Verifique os dados e tente novamente!',
    );
  }
};
