import axios from 'axios';
import _ from 'lodash';

import { sendErroRequest } from '../utils/Notification';
import sentinelaURLs from '../utils/sentinelaURLs';

class Util {
  // static getBaseUrl() {
  //   return location.href.indexOf('localhost') > 0 ? 'http://localhost:6004/api' : '/api';
  // }

  static getBaseUrlSentinela() {
    return `${sentinelaURLs[process.env.NODE_ENV]}/api`;
  }

  static getUrlSentinela() {
    return sentinelaURLs[process.env.NODE_ENV];
  }

  static getHeaders() {
    return {
      headers: {
        Authorization: `Bearer ${sessionStorage.token}`,
        Accept: 'application/json',
        ServiceIdentify: `${sessionStorage.tenant}`,
      },
    };
  }

  static getHeadersFunctionName(functionName) {
    return {
      headers: {
        Authorization: `Bearer ${sessionStorage.token}`,
        Accept: 'application/json',
        ServiceIdentify: `${sessionStorage.tenant}`,
        NomeFuncao: functionName,
      },
    };
  }

  static getHeaders2(token) {
    return { headers: { Authorization: `Bearer ${token}`, Accept: 'application/json' } };
  }

  static guidEmpty() {
    return '00000000-0000-0000-0000-000000000000';
  }

  static newGuid() {
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }

  static retornaObjetoById(value, field, array) {
    let result = {};

    if (array) {
      _.forEach(array, (item) => {
        if (item[field] === value) {
          result = item;
        }
      });
    }

    if (result === {}) return undefined;
    return result;
  }

  static possuiPermissao(controller, action) {
    let temPermissao = true;
    const permissoes = JSON.parse(sessionStorage.getItem('permissoes'));
    _.forEach(permissoes, (permissao) => {
      if (permissao.rota === controller) {
        _.forEach(permissao.operacoes, (operacao) => {
          if (operacao.rota === action && !operacao.permitido) {
            temPermissao = false;
          }
        });
      }
    });

    return temPermissao;
  }

  static async fazerDownloadArquivoRemessa(url, nomeArquivo) {
    try {
      const { data: dataDownload } = await axios.get(url, {
        responseType: 'blob',
      });
      const link = document.createElement('a');
      const nomeArquivoCompleto = nomeArquivo.split('_');
      const nomeArquivoFormatado = nomeArquivoCompleto[0];
      link.href = window.URL.createObjectURL(dataDownload);
      link.setAttribute('download', `${nomeArquivoFormatado}`);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      sendErroRequest(
        error,
        'Ocorreu um erro ao tentar fazer o download desejado. Tente novamente.',
      );
    }
  }

  static async fazerDownload(url, nomeArquivo) {
    try {
      const { data: dataDownload } = await axios.get(url, {
        responseType: 'blob',
      });
      const link = document.createElement('a');

      const urlSplit = url.split('.');
      const extensao = urlSplit[urlSplit.length - 1];

      link.href = window.URL.createObjectURL(dataDownload);
      link.setAttribute('download', `${nomeArquivo || 'arquivo'}.${extensao}`);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      error(error, 'Ocorreu um erro ao tentar fazer o download desejado. Tente novamente.');
    }
  }
}

export default Util;
