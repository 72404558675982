import jQuery from 'jquery';
import React, { useEffect, useState, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import './styles/Notificacao.css';

import Tooltip from '@material-ui/core/Tooltip';
import { smoothlyMenu } from '../helpers';

import ProgressBar from '../../utils/progressBar';

import { carregarRelatorios, limparListaRelatorio, logOutUser } from '../../actions';

const TopNavbar = ({ pessoaLogada }) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = () => {
    setShow(!show);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(carregarRelatorios());
    return () => {
      cleanRelatorios();
    };
  }, [dispatch]);

  const relatorioData = useSelector(state => state.relatorio);

  const toggleNavigation = e => {
    e.preventDefault();
    jQuery('body').toggleClass('mini-navbar');
    smoothlyMenu();
  };

  function refreshPage() {
    window.location.reload();
  }

  function cleanRelatorios() {
    sessionStorage.setItem('messages', JSON.stringify([]));
    this.forceUpdate();
  }
  function logout() {
    dispatch(logOutUser());
  }
  const content = (
    <div
      className={relatorioData.relatorios.length > 0 ? 'pre-scrollable' : 'row'}
      style={{ height: relatorioData.relatorios.length > 0 ? 120 : 30 }}
    >
      {relatorioData.relatorios.length > 0 ? (
        relatorioData.relatorios.map(item => {
          return (
            <div key={item.id}>
              <p
                className="rowRelatorio"
                style={{
                  minWidth: `${(window.innerWidth * 15) / 100}`,
                  marginRight: 15,
                  textAlign: 'right',
                  fontSize: '12px',
                }}
              >
                {item.nome}

                <button
                  className="`btn btn-primary m-t"
                  type="button"
                  onClick={() => window.open(item.url, '_blank')}
                  style={{ marginLeft: '5px', border: '1px solid' }}
                >
                  <i className="fa fa-download fa-1x" />
                </button>
              </p>
            </div>
          );
        })
      ) : (
        <div className="col-md-12">{<span>Nada por aqui</span>}</div>
      )}
    </div>
  );
  const linearBarProgress = (
    <div style={{ textAlign: 'center' }}>
      {' '}
      <ProgressBar />
      {<span>Processando</span>}
    </div>
  );

  function Titulo() {
    return (
      <div style={{ textAlign: 'center' }}>
        <a href="#/gerenciador-relatorios">Gerenciador de Relatórios</a>
        {relatorioData.relatorios.length > 0 && (
          <Tooltip title="Limpar gerenciador de relatórios!" className="pull-right">
            <div style={{ marginTop: 3 }}>
              <a onClick={() => dispatch(limparListaRelatorio())}>
                <i className="fa fa-trash" />
              </a>
            </div>
          </Tooltip>
        )}
      </div>
    );
  }

  return (
    <div className="row border-bottom">
      <nav className="navbar navbar-static-top" role="navigation" style={{ marginBottom: 0 }}>
        <div className="navbar-header">
          <a
            className="minimalize-styl-2 btn btn-primary"
            onClick={e => toggleNavigation(e)}
            href="#"
          >
            <i className="fa fa-bars" />
          </a>
        </div>
        <ul className="nav navbar-top-links navbar-right">
          <li>
            <Tooltip title="Central de Atendimento!">
              <a href="https://atendimento.tron.com.br" target="_blank">
                Central de Atendimento
              </a>
            </Tooltip>
          </li>
          <li>
            <Tooltip title="Recarregar página!">
              <button
                type="button"
                className="btn btn-default"
                data-toggle="tooltip"
                data-placement="bottom"
                data-original-title="Tooltip on bottom"
                style={{ marginRight: '10px', background: 'none', border: 'none' }}
                onClick={() => refreshPage()}
              >
                <i className="fa fa-refresh" />
              </button>
            </Tooltip>
          </li>
          <li className="dropdown">
            <Tooltip title="Notificações">
              <a
                className="dropdown-toggle count-info"
                data-toggle="dropdown"
                href="#"
                aria-expanded="false"
              >
                <i className="fa fa-bell" />
                {relatorioData?.relatorios?.length > 0 && (
                  <span className="label label-primary">{relatorioData?.relatorios?.length}</span>
                )}
              </a>
            </Tooltip>
            <ul className="dropdown-menu dropdown-alerts">
              <li>
                <div>
                  <Titulo />
                </div>
              </li>
              <li className="dropdown-divider" />

              <li>
                <div style={{ textAlign: 'center' }}>
                  {relatorioData.aguardandoRelatorio === false ? content : linearBarProgress}
                </div>
              </li>
            </ul>
          </li>
          <li>
            <a
              href={process.env.REACT_APP_SENTINELA}
              onClick={() => logout()}
              style={{ marginRight: '25px' }}
            >
              <i className="fa fa-sign-out" /> Sair
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

const mapStateToProps = state => ({
  pessoaLogada: state.usuario.pessoaLogada,
  relatorio: state.relatorio,
});

export default connect(mapStateToProps)(TopNavbar);
