// #region ROTA
export const ROT_SET_ROTAS_MENUS_SUCCESS = 'ROT_SET_ROTAS_MENUS_SUCCESS';
export const ROT_SET_ROTAS_MENUS_FAIL = 'ROT_SET_ROTAS_MENUS_FAIL';
// #endregion

// #region USUARIO
export const USR_GET_PESSOA_LOGADA = 'USR_GET_PESSOA_LOGADA';
export const USR_SET_ESTADO_INICIAL_USUARIO_LOGADO = 'USR_SET_ESTADO_INICIAL_USUARIO_LOGADO';
export const USR_PESSOA_JA_LOGADA = 'USR_PESSOA_JA_LOGADA';
// #endregion

//#region DASHBOARD

export const DASH_SET_ESTADO_INICIAL = 'DASH_SET_ESTADO_INICIAL';

export const DASH_FATURA_SET_DADOS = 'DASH_FATURA_SET_DADOS';
export const DASH_FATURA_PROCESSANDO = 'DASH_FATURA_PROCESSANDO';

export const DASH_UTILIZACAO_PORTAL_SET_DADOS = 'DASH_UTILIZACAO_PORTAL_SET_DADOS';
export const DASH_UTILIZACAO_PORTAL_PROCESSANDO = 'DASH_UTILIZACAO_PORTAL_PROCESSANDO';

export const DASH_VISUALIZACAO_BOLETOS_SET_DADOS = 'DASH_VISUALIZACAO_BOLETOS_SET_DADOS';
export const DASH_VISUALIZACAO_BOLETOS_PROCESSANDO = 'DASH_VISUALIZACAO_BOLETOS_PROCESSANDO';

export const DASH_EVOLUCAO_TAXAS_SET_DADOS = 'DASH_EVOLUCAO_TAXAS_SET_DADOS';
export const DASH_EVOLUCAO_TAXAS_PROCESSANDO = 'DASH_EVOLUCAO_TAXAS_PROCESSANDO';

//#endregion

// #region CONTA
export const CNT_SET_ESTADO_INICIAL = 'CNT_SET_ESTADO_INICIAL';
export const CNT_GET_CONTAS = 'CNT_GET_CONTAS';
export const CNT_PROCESSANDO_CONTA = 'CNT_PROCESSANDO_CONTA';
export const CNT_PROCESSANDO_UPLOAD_CONTA = 'CNT_PROCESSANDO_CNT_PROCESSANDO_UPLOAD_CONTA';
export const CNT_GET_CONTAS_NIVEL_UM = 'CNT_GET_CONTAS_NIVEL_UM';
export const CNT_GET_CONTAS_NIVEL_DOIS = 'CNT_GET_CONTAS_NIVEL_DOIS';
export const CNT_GET_CONTAS_NIVEL_TRES = 'CNT_GET_CONTAS_NIVEL_TRES';
export const CNT_GET_CONTAS_RECEITA = 'CNT_GET_CONTAS_RECEITA';
export const CNT_GET_CONTAS_DESPESA = 'CNT_GET_CONTAS_DESPESA';
export const CNT_GET_CONTAS_CONTRA_PARTIDA = 'CNT_GET_CONTAS_CONTRA_PARTIDA';
export const CNT_GET_CONTAS_SALDO = 'CNT_GET_CONTAS_SALDO';
export const CNT_GET_CONTA_ID_SALDO = 'CNT_GET_CONTA_ID_SALDO';
export const CNT_ADICIONANDO_CONTA = 'CNT_ADICIONANDO_CONTA';

export const CNT_SET_LISTA_CONTAS = 'CNT_SET_LISTA_CONTAS';
export const CNT_SET_CONTAS_RECURSO_FINANCEIRO = 'CNT_SET_CONTAS_RECURSO_FINANCEIRO';
export const CNT_SET_CONTAS_SELECIONADAS = 'CNT_SET_CONTAS_SELECIONADAS';
// #endregion

// #region RATEIO
export const RAT_SET_ESTADO_INICIAL = 'RAT_SET_ESTADO_INICIAL';
export const RAT_GET_RATEIOS = 'RAT_GET_RATEIOS';
export const RAT_SET_RATEIO_BY_ID = 'RAT_SET_RATEIO_BY_ID';
export const RAT_PROCESSANDO_RATEIO = 'RAT_PROCESSANDO_RATEIO';
export const RAT_SET_PERCENTUAIS_RATEIO = 'RAT_SET_PERCENTUAIS_RATEIO';
// #endregion

// #region IndiceCorrecao
export const IND_SET_ESTADO_INICIAL = 'IND_SET_ESTADO_INICIAL';
export const IND_GET_INDEXADORES = 'IND_GET_INDEXADORES';
export const IND_SET_INDEXADORES_BY_ID = 'IND_SET_INDEXADORES_BY_ID';
export const IND_PROCESSANDO_INDEXADORES = 'IND_PROCESSANDO_INDEXADORES';

// #region GERENCIADOR DE RELATORIOS
export const REL_SET_ESTADO_INICIAL = 'REL_SET_ESTADO_INICIAL';
export const REL_SET_RELATORIOS = 'REL_SET_RELATORIOS';
export const REL_PROCESSANDO_RELATORIO = 'REL_PROCESSANDO_RELATORIO';
export const REL_AGUARDANDO_RELATORIO = 'REL_AGUARDANDO_RELATORIO';

export const REL_SET_DADOS_EVOLUCAO_INADIMPLENCIA = 'REL_SET_DADOS_EVOLUCAO_INADIMPLENCIA';
export const REL_SET_DADOS_DEMONSTRATIVO_SALDO_CONTA = 'REL_SET_DADOS_DEMONSTRATIVO_SALDO_CONTA';
// #endregion

// #region ESCRITORIO_COBRANCA
export const ECB_SET_ESTADO_INICIAL = 'ECB_SET_ESTADO_INICIAL';
export const ECB_GET_ESCRITORIOS_COBRANCA = 'ECB_GET_ESCRITORIOS_COBRANCA';
export const ECB_PROCESSANDO_ESCRITORIO_COBRANCA = 'ECB_PROCESSANDO_ESCRITORIO_COBRANCA';
export const ECB_GET_ESCRITORIOS_COBRANCA_BY_ID = 'ECB_GET_ESCRITORIOS_COBRANCA_BY_ID';
// #endregion

// #region ENUMERADORES
export const ENUM_SET_TIPOS_VALOR = 'ENUM_SET_TIPOS_VALOR';
export const ENUM_SET_TIPOS_DESPESA_RECEITA = 'ENUM_SET_TIPOS_DESPESA_RECEITA';
export const ENUM_SET_TIPOS_CALCULO_FUNDO_RESERVA = 'ENUM_SET_TIPOS_CALCULO_FUNDO_RESERVA';
export const ENUM_SET_TIPOS_CONSUMO = 'ENUM_SET_TIPOS_CONSUMO';
export const ENUM_SET_TIPOS_LANCAMENTO = 'ENUM_SET_TIPOS_LANCAMENTO';
export const ENUM_SET_TIPOS_RATEIO = 'ENUM_SET_TIPOS_RATEIO';
export const ENUM_SET_TIPOS_PATRIMONIO = 'ENUM_SET_TIPOS_PATRIMONIO';
export const ENUM_SET_NATUREZAS_PATRIMONIO = 'ENUM_SET_NATUREZAS_PATRIMONIO';
export const ENUM_SET_MODELOS_BOLETO = 'ENUM_SET_MODELOS_BOLETO';
export const ENUM_SET_DESCRICAO_PREVISAO_EMISSAO_BOLETO =
  'ENUM_SET_DESCRICAO_PREVISAO_EMISSAO_BOLETO';
export const ENUM_SET_TIPOS_INSCRICAO = 'ENUM_SET_TIPOS_INSCRICAO';
export const ENUM_SET_SISTEMAS_DE_COBRANCA = 'ENUM_SET_SISTEMAS_DE_COBRANCA';
export const ENUM_SET_TIPOS_VALOR_JURO = 'ENUM_SET_TIPOS_VALOR_JURO';
export const ENUM_SET_TIPOS_VALOR_MULTA = 'ENUM_SET_TIPOS_VALOR_MULTA';
export const ENUM_SET_MODELOS_CARTEIRA = 'ENUM_SET_MODELOS_CARTEIRA';
export const ENUM_SET_STATUS_FATURA = 'ENUM_SET_STATUS_FATURA';
export const ENUM_SET_STATUS_ACORDO_SUCCESS = 'ENUM_SET_STATUS_ACORDO_SUCCESS';
export const ENUM_SET_STATUS_ACORDO_FAIL = 'ENUM_SET_STATUS_ACORDO_FAIL';
export const ENUM_SET_TIPOS_FATURA = 'ENUM_SET_TIPOS_FATURA';
export const ENUM_SET_TIPOS_RELATORIOS = 'ENUM_SET_TIPOS_RELATORIOS';
// #endregion

// #region COMUM
export const COMUM_GET_ESTADOS = 'COMUM_GET_ESTADOS';
export const COMUM_GET_CIDADES = 'COMUM_GET_CIDADES';
// #endregion

// #region CONDOMINO
export const CDM_SET_ESTADO_INICIAL = 'CDM_SET_ESTADO_INICIAL';
export const CDM_GET_CONDOMINOS = 'CDM_GET_CONDOMINOS';
export const CDM_PROCESSANDO_CONDOMINO = 'CDM_PROCESSANDO_CONDOMINO';
export const CDM_PROCESSANDO_UPLOAD_CONDOMINO = 'CDM_PROCESSANDO_UPLOAD_CONDOMINO';
export const CDM_SET_MASCARA_INSCRICAO = 'CDM_SET_MASCARA_INSCRICAO';
export const CDM_SET_LISTA_EMAILS = 'CDM_SET_LISTA_EMAILS';
export const CDM_ADD_EMAIL_CONDOMINO = 'CDM_ADD_EMAIL_CONDOMINO';
export const CDM_GET_PROPRIETARIOS = 'CDM_GET_PROPRIETARIOS';
export const CDM_GET_CONDOMINO_BY_ID = 'CDM_GET_CONDOMINO_BY_ID';
export const CDM_SET_CONDOMINOS_EDITAR = 'CDM_SET_CONDOMINOS_EDITAR';
// #endregion

// #region HISTORICO
export const HST_SET_ESTADO_INICIAL = 'HST_SET_ESTADO_INICIAL';
export const HST_GET_HISTORICOS = 'HST_GET_HISTORICOS';
export const HST_PROCESSANDO_HISTORICO = 'HST_PROCESSANDO_HISTORICO';
export const HST_GET_HISTORICOS_BY_ID = 'HST_GET_HISTORICOS_BY_ID';
// #endregion

// #region INSTRUCAO_BANCARIA
export const IBC_SET_ESTADO_INICIAL = 'IBC_SET_ESTADO_INICIAL';
export const IBC_GET_INSTRUCOES_BANCARIAS = 'IBC_GET_INSTRUCOES_BANCARIAS';
export const IBC_PROCESSANDO_INSTRUCAO_BANCARIA = 'IBC_PROCESSANDO_INSTRUCAO_BANCARIA';
export const IBC_GET_INSTRUCOES_BANCARIAS_BY_ID = 'IBC_GET_INSTRUCOES_BANCARIAS_BY_ID';
// #endregion

// #region LOCAL
export const LOC_SET_ESTADO_INICIAL = 'LOC_SET_ESTADO_INICIAL';
export const LOC_GET_LOCAIS = 'LOC_GET_LOCAIS';
export const LOC_PROCESSANDO_LOCAL = 'LOC_PROCESSANDO_LOCAL';
export const LOC_GET_LOCAL_BY_ID = 'LOC_GET_LOCAL_BY_ID';
// #endregion

// #region PATRIMONIO
export const PTM_SET_ESTADO_INICIAL = 'PTM_SET_ESTADO_INICIAL';
export const PTM_GET_PATRIMONIOS = 'PTM_GET_PATRIMONIOS';
export const PTM_PROCESSANDO_PATRIMONIO = 'PTM_PROCESSANDO_PATRIMONIO';
export const PTM_GET_PATRIMONIOS_BY_ID = 'PTM_GET_PATRIMONIOS_BY_ID';
// #endregion

// #region PORTADOR
export const PTD_SET_ESTADO_INICIAL = 'PTD_SET_ESTADO_INICIAL';
export const PTD_GET_PORTADORES = 'PTD_GET_PORTADORES';
export const PTD_PROCESSANDO_PORTADOR = 'PTD_PROCESSANDO_PORTADOR';
export const PTD_SET_MASCARA_INSCRICAO_CEDENTE = 'PTD_SET_MASCARA_INSCRICAO_CEDENTE';
export const PTD_GET_BANCOS = 'PTD_GET_BANCOS';
export const PTD_GET_PORTADORES_BY_ID = 'PTD_GET_PORTADORES_BY_ID';
// #endregion

// #region UNIDADE_CENTRAL
export const UCT_SET_ESTADO_INICIAL = 'UCT_SET_ESTADO_INICIAL';
export const UCT_GET_UNIDADES_CENTRAIS = 'UCT_GET_UNIDADES_CENTRAIS';
export const UCT_GET_UNIDADES_CENTRAIS_BY_ID = 'UCT_GET_UNIDADES_CENTRAIS_BY_ID';
export const UCT_PROCESSANDO_UNIDADE_CENTRAL = 'UCT_PROCESSANDO_UNIDADE_CENTRAL';
// #endregion

// #region UNIDADE
export const UND_SET_ESTADO_INICIAL = 'UND_SET_ESTADO_INICIAL';
export const UND_GET_UNIDADES = 'UND_GET_UNIDADES';
export const UND_GET_UNIDADES_BY_ID = 'UND_GET_UNIDADES_BY_ID';
export const UND_PROCESSANDO_UNIDADE = 'UND_PROCESSANDO_UNIDADE';
export const UND_CARREGAR_MEDIDORES = 'UND_CARREGAR_MEDIDORES';
export const UND_ADICIONANDO_MEDIDOR = 'UND_ADICIONANDO_MEDIDOR';
export const UND_GET_UNIDADES_COM_MEDIDOR = 'UND_GET_UNIDADES_COM_MEDIDOR';
export const UND_ADD_MEDIDORES = 'UND_ADD_MEDIDORES';
export const UND_SET_UNIDADES_EDITAR = 'UND_SET_UNIDADES_EDITAR';
// #endregion

// #region SINDICO
export const SDC_SET_ESTADO_INICIAL = 'SDC_SET_ESTADO_INICIAL';
export const SDC_GET_SINDICOS = 'SDC_GET_SINDICOS';
export const SDC_PROCESSANDO_SINDICO = 'SDC_PROCESSANDO_SINDICO';
export const SDC_GET_UNIDADES_SINDICO = 'SDC_GET_UNIDADES_SINDICO';
export const SDC_GET_UNIDADES_SUB_SINDICO = 'SDC_GET_UNIDADES_SUB_SINDICO';
export const SDC_GET_SINDICOS_BY_ID = 'SDC_GET_SINDICOS_BY_ID';
// #endregion

// #region  UNIDADE_ISENTA
export const UDI_SET_ESTADO_INICIAL = 'UDI_SET_ESTADO_INICIAL';
export const UDI_GET_UNIDADES_ISENTAS = 'UDI_GET_UNIDADES_ISENTAS';
export const UDI_GET_UNIDADES_ISENTAS_BY_ID = 'UDI_GET_UNIDADES_ISENTAS_BY_ID';
export const UDI_PROCESSANDO_UNIDADE_ISENTA = 'UDI_PROCESSANDO_UNIDADE_ISENTA';
// #endregion

// #region TABELA_PRECO
export const TPC_SET_ESTADO_INICIAL = 'TPC_SET_ESTADO_INICIAL';
export const TPC_GET_TABELAS_PRECO = 'TPC_GET_TABELAS_PRECO';
export const TPC_GET_TABELAS_PRECO_BY_ID = 'TPC_GET_TABELAS_PRECO_BY_ID';
export const TPC_PROCESSANDO_TABELA_PRECO = 'TPC_PROCESSANDO_TABELA_PRECO';
export const TPC_GET_CONTAS_DESPESA = 'TPC_GET_CONTAS_DESPESA';
// #endregion

// #region TAXA
export const TXA_SET_ESTADO_INICIAL = 'TXA_SET_ESTADO_INICIAL';
export const TXA_GET_TAXAS = 'TXA_GET_TAXAS';
export const TXA_GET_TAXAS_MARCADAS = 'TXA_GET_TAXAS_MARCADAS';
export const TXA_GET_TAXAS_BY_ID = 'TXA_GET_TAXAS_BY_ID';
export const TXA_PROCESSANDO_TAXA = 'TXA_PROCESSANDO_TAXA';
// #endregion

// #region FATURA
export const FTR_SET_ESTADO_INICIAL = 'FTR_SET_ESTADO_INICIAL';
export const FTR_GET_FATURAS = 'FTR_GET_FATURAS';
export const FATURAS_SELECIONADAS_CHECKED = 'FATURAS_SELECIONADAS_CHECKED';
export const FTR_SET_LISTA_ITENS_FATURA = 'FTR_SET_LISTA_ITENS_FATURA';
export const FTR_ADICIONANDO_ITEM_FATURA = 'FTR_ADICIONANDO_ITEM_FATURA';
export const FTR_SHOW_MODAL_ITEM_FATURA = 'FTR_SHOW_MODAL_ITEM_FATURA';
export const FTR_PROCESSANDO_FATURA = 'FTR_PROCESSANDO_FATURA';
export const FTR_GET_FATURAS_EM_ABERTO = 'FTR_GET_FATURAS_EM_ABERTO';
export const FTR_GET_FATURAS_EM_ABERTO_MARCADAS = 'FTR_GET_FATURAS_EM_ABERTO_MARCADAS';
export const FTR_SET_FATURA_SELECIONADA = 'FTR_SET_FATURA_SELECIONADA';
export const FTR_SET_VALOR_TOTAL_FATURAS = 'FTR_SET_VALOR_TOTAL_FATURAS';
export const FTR_GERANDO_FATURAS = 'FTR_GERANDO_FATURAS';
export const FTR_SHOW_MODAL_BAIXA_FATURA = 'FTR_SHOW_MODAL_BAIXA_FATURA';
export const FTR_PROCESSANDO_BAIXA_FATURA = 'FTR_PROCESSANDO_BAIXA_FATURA';
export const FTR_BAIXA_COLETIVA_FATURAS = 'FTR_BAIXA_COLETIVA_FATURAS';
// #endregion

// #region SALDO_CONTA
export const SLD_SET_ESTADO_INICIAL = 'SLD_SET_ESTADO_INICIAL';
export const SLD_GET_SALDOS_CONTA = 'SLD_GET_SALDOS_CONTA';
export const SLD_PROCESSANDO_SALDO_CONTA = 'SLD_PROCESSANDO_SALDO_CONTA';
// #endregion

// #region FECHAMENTO_PERIODO
export const FCP_SET_ESTADO_INICIAL = 'FCP_SET_ESTADO_INICIAL';
export const FCP_GET_FECHAMENTOS_PERIODO = 'FCP_GET_FECHAMENTOS_PERIODO';
export const FCP_PROCESSANDO_FECHAMENTO_PERIODO = 'FCP_PROCESSANDO_FECHAMENTO_PERIODO';
// #endregion

// #region REMESSA
export const RMS_SET_ESTADO_INICIAL = 'RMS_SET_ESTADO_INICIAL';
export const RMS_SET_REMESSAS = 'RMS_SET_REMESSAS';
export const RMS_PROCESSANDO_REMESSA = 'RMS_PROCESSANDO_REMESSA';
export const RMS_PROCESSANDO_FATURA_REMESSA = 'RMS_PROCESSANDO_FATURA_REMESSA';
export const RMS_SET_NUMERO_REMESSA = 'RMS_SET_NUMERO_REMESSA';
export const RMS_SET_FATURA_REMESSA = 'RMS_SET_FATURA_REMESSA';
export const RMS_SET_DADOS_FATURA_REMESSA = 'RMS_SET_DADOS_FATURA_REMESSA';
// #endregion

// #region LEITURA_DE_CONSUMO
export const LDC_SET_ESTADO_INICIAL = 'LDC_SET_ESTADO_INICIAL';
export const LDC_GET_LEITURAS_DE_CONSUMO = 'LDC_GET_LEITURAS_DE_CONSUMO';
export const LDC_PROCESSANDO_LEITURA_DE_CONSUMO = 'LDC_PROCESSANDO_LEITURA_DE_CONSUMO';
// #endregion

// #region LANCAMENTO
export const LCM_SET_ESTADO_INICIAL = 'LCM_SET_ESTADO_INICIAL';
export const LCM_GET_LANCAMENTOS = 'LCM_GET_LANCAMENTOS';
export const LCM_GET_LANCAMENTOS_BY_ID = 'LCM_GET_LANCAMENTOS_BY_ID';
export const LCM_PROCESSANDO_LANCAMENTO = 'LCM_PROCESSANDO_LANCAMENTO';
export const LCM__LANCAMENTOS_CHECKED = 'LCM__LANCAMENTOS_CHECKED';
// #endregion

// #region PREVISAO
export const PRV_SET_ESTADO_INICIAL = 'PRV_SET_ESTADO_INICIAL';
export const PRV_GET_PREVISOES = 'PRV_GET_PREVISOES';
export const PRV_GET_PREVISOES_CHECKED = 'PRV_GET_PREVISOES_CHECKED';
export const PRV_GET_PREVISOES_IMPORTACAO = 'PRV_GET_PREVISOES_IMPORTACAO';
export const PRV_PROCESSANDO_PREVISAO = 'PRV_PROCESSANDO_PREVISAO';
export const PRV_SET_ITENS_PREVISAO = 'PRV_SET_ITENS_PREVISAO';
export const PRV_SET_PREVISAO_EDIT = 'PRV_SET_PREVISAO_EDIT';
export const PRV_SHOW_MODAL_MEMORIA_CALCULO_ITEM_PREVISAO =
  'PRV_SHOW_MODAL_MEMORIA_CALCULO_ITEM_PREVISAO';
export const PRV_SHOW_MODAL_GERAR_PREVISAO_PARCELAMENTO =
  'PRV_SHOW_MODAL_GERAR_PREVISAO_PARCELAMENTO';
// #endregion

// #region ACORDO
export const ACD_SET_ESTADO_INICIAL = 'ACD_SET_ESTADO_INICIAL';
export const ACD_GET_ACORDOS = 'ACD_GET_ACORDOS';
export const ACD_GET_ACORDOS_BY_ID = 'ACD_GET_ACORDOS_BY_ID';
export const ACD_PROCESSANDO_ACORDO = 'ACD_PROCESSANDO_ACORDO';
export const ACD_GET_ACORDOS_TOTAL = 'ACD_GET_ACORDOS_TOTAL';
export const ACD_SET_PARCELAS_SIMULADAS = 'ACD_SET_PARCELAS_SIMULADAS';
// #endregion

// #region DEMONSTRATIVO TAXA FIXA
export const DTF_SET_ESTADO_INICIAL = 'DTF_SET_ESTADO_INICIAL';
export const DTF_GET_DEMONSTRATIVO_TAXA_FIXA_BY_ID = 'DTF_GET_DEMONSTRATIVO_TAXA_FIXA_BY_ID';
export const DTF_GET_DEMONSTRATIVOS_TAXA_FIXA = 'DTF_GET_DEMONSTRATIVOS_TAXA_FIXA';
export const DTF_PROCESSANDO_DEMONSTRATIVO_TAXA_FIXA = 'DTF_PROCESSANDO_DEMONSTRATIVO_TAXA_FIXA';
// #endregion

//#region PARCELAMENTO

export const PRC_SET_ESTADO_INICIAL = 'PRC_SET_ESTADO_INICIAL';
export const PRC_GET_PARCELAMENTOS = 'PRC_GET_PARCELAMENTOS';
export const PRC_GET_PARCELAMENTOS_BY_ID = 'PRC_GET_PARCELAMENTOS_BY_ID';
export const PRC_PROCESSANDO_PARCELAMENTO = 'PRC_PROCESSANDO_PARCELAMENTO';
export const PRC_SET_PARCELAS_SIMULADAS = 'PRC_SET_PARCELAS_SIMULADAS';
export const PRC_SET_PARCELAS_ANALITICAS = 'PRC_SET_PARCELAS_ANALITICAS';
export const PRC_SET_UNIDADES_ADIANTAMENTO = 'PRC_SET_UNIDADES_ADIANTAMENTO';

// #endregion

//#region RETORNO BANCARIO

export const RET_SET_ESTADO_INICIAL = 'RET_SET_ESTADO_INICIAL';
export const RET_SET_RETORNO_BANCARIO = 'RET_SET_RETORNO_BANCARIO';
export const RET_PROCESSANDO_RETORNO_BANCARIO = 'RET_PROCESSANDO_RETORNO_BANCARIO';

//#endregion

//#region PARAMETRO

export const PRM_SET_ESTADO_INICIAL = 'PRM_SET_ESTADO_INICIAL';
export const PRM_GET_PARAMETROS = 'PRM_GET_PARAMETROS';
export const PRM_PROCESSANDO_PARAMETRO = 'PRM_PROCESSANDO_PARAMETRO';
export const PRM_GET_SINDICOS_CONDOMINO = 'PRM_GET_SINDICOS_CONDOMINO';
export const PRM_SET_LISTA_EMAILS_CONDOMINIO = 'PRM_SET_LISTA_EMAILS_CONDOMINIO';
export const PRM_GET_DESCRICAO_PREVISAO_EMISSAO_BOLETO =
  'PRM_GET_DESCRICAO_PREVISAO_EMISSAO_BOLETO';
export const PRM_GET_MODELOS_BOLETO = 'PRM_GET_MODELOS_BOLETO';

//#endregion

//#region  PROTOCOLOS
export const PRT_SET_ESTADO_INICIAL = 'PTR_SET_ESTADO_INICIAL';
export const PTR_PROCESSANDO_NOTIFICACAO = 'PTR_PROCESSANDO_NOTIFICACAO';
//#region

// #region OBSERVACOES
export const OBS_SET_ESTADO_INICIAL = 'OBS_SET_ESTADO_INICIAL';
export const OBS_GET_OBSERVACOES = 'OBS_GET_OBSERVACOES';
export const OBS_PROCESSANDO_OBSERVACOES = 'OBS_PROCESSANDO_OBSERVACOES';
export const OBS_GET_OBSERVACOES_BY_ID = 'OBS_GET_OBSERVACOES_BY_ID';
// #endregion

// #region LOGS
export const LOG_GET_LOGS = 'LOG_GET_LOGS';
export const LOG_PROCESSANDO_LOGS = 'LOG_PROCESSANDO_LOGS';
// #endregion
